<div class="flex-container flex-container-column flex-gap flex-align-items-center flex-justify-center p-3">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>Payroll</h2>
		</mat-card-title>

		<mat-card-content>
			<form [formGroup]="range">
				<mat-form-field appearance="fill">
					<mat-label>Enter a date range</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate formControlName="start" placeholder="Start date" />
						<input matEndDate formControlName="end" placeholder="End date" />
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>

					@if (range.controls.start.hasError('matStartDateInvalid')) {
					<mat-error>Invalid start date</mat-error>
					} @else if(range.controls.end.hasError('matEndDateInvalid')) {
					<mat-error>Invalid end date</mat-error>
					}
				</mat-form-field>
			</form>

			<div>
				<user-picker [validators]="[{key: 'required', message: 'Store is <strong>required</strong>'}]"> </user-picker>
			</div>

			<div>
				<div><strong>Total Hours:</strong> {{ totalHours | userFriendlyHours }}</div>

				<div><strong>Total Repair Order Hours: </strong> {{ repairOrderHours | userFriendlyHours }}</div>
			</div>

			<div style="overflow: auto; max-width: calc(100vw - 158px); max-height: 50vh">
				<table mat-table [dataSource]="matTableDataSource">
					<ng-container matColumnDef="saveButton">
						<div>
							<th mat-header-cell *matHeaderCellDef></th>
							<td style="padding-right: 15px" mat-cell *matCellDef="let element">
								@if (userService.isAdmin()) {
								<loading-button
									[iconClass]="'save'"
									[buttonClass]="'btn'"
									[action]="upsertUserTime"
									[actionArgs]="element"
									[disabled]="loadingService.loading"></loading-button>
								}
							</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="day">
						<div>
							<th mat-header-cell *matHeaderCellDef>Day</th>
							<td style="padding-right: 15px" mat-cell *matCellDef="let element">{{ element.day | date: 'shortDate' : 'GMT-400' }}</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="repairOrderHours">
						<div>
							<th mat-header-cell *matHeaderCellDef>Repair Order Hours</th>
							<td style="padding-right: 15px" mat-cell *matCellDef="let element">{{ element.repairOrderHours }}</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="workedHours">
						<div>
							<th mat-header-cell *matHeaderCellDef>Worked Hours</th>
							<td style="padding-right: 15px" mat-cell *matCellDef="let element">{{ element.workedHours | userFriendlyHours }}</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="clockIn">
						<div>
							<th mat-header-cell *matHeaderCellDef>Clock In</th>
							<td style="padding-right: 15px" mat-cell *matCellDef="let element">
								@if (element.clockIn) {
								<time-picker [readonly]="userService.isAdmin() ? false : true" [(datetime)]="element.clockIn.date"></time-picker>
								}
							</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="clockOut">
						<div>
							<th mat-header-cell *matHeaderCellDef>Clock Out</th>
							<td style="padding-right: 15px" mat-cell *matCellDef="let element">
								@if (element.clockOut) {
								<time-picker [readonly]="userService.isAdmin() ? false : true" [(datetime)]="element.clockOut.date"></time-picker>
								}
							</td>
						</div>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
				</table>
			</div>

			<mat-paginator class="flex-container flex-justify-left" showFirstLastButtons="true" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10">
			</mat-paginator>

			<br />

			<mat-divider></mat-divider>

			<br />

			<div class="flex-container flex-container-row flex-gap">
				<loading-button [text]="'Search'" [action]="refreshPayrollInformationClicked"></loading-button>

				@if (userService.isAdmin()) {
				<loading-button [text]="'Add User Time'" [action]="addUserTimeClicked"></loading-button>

				<loading-button [text]="'Download Time Sheet'" [action]="downloadUserTimeSheetClicked"></loading-button>
				}
			</div>
		</mat-card-content>
	</mat-card>
</div>
