import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { Stage } from '../../../classes/purchaseOrder';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { DeviceService } from 'src/app/services/device.service';
import { Vehicle } from 'src/app/classes/vehicle';
import { debounceTime } from 'rxjs/operators';

@Component({
	templateUrl: './purchaseOrder.html',
	styleUrls: ['./purchaseOrder.scss'],
	selector: 'new-purchase-order',
})
export class NewPurchaseOrderComponent extends FormComponent {
	public orderForm: FormGroup = null;

	public selectedFiles: Array<File> = [];

	public jobControl = new FormControl('', [Validators.minLength(1)]);

	constructor(public userService: UserService, private api: ApiService, private formBuilder: FormBuilder, public device: DeviceService) {
		super();
		this.initializeOrderForm();
	}

	public initializeOrderForm() {
		this.orderForm = this.formBuilder.group({
			id: new FormControl(null),
			user_id: new FormControl(this.userService.user.id),
			photos: new FormControl(null),
			total_cost: new FormControl(0, [Validators.required]),
			name: new FormControl({ value: null, disabled: true }, [Validators.required]),
			store_id: new FormControl(null, [Validators.required]),
			stocked: new FormControl(false),
			fuel: new FormControl(false),

			//      Will get submitted as moved to in receiving
			purchaseOrderProcessingStepId: new FormControl(Stage.InReceiving, [Validators.required]),
		});

		//		Automatically pull in the next PO number for them
		this.setNextPurchaseOrderNumber();

		this.jobControl.valueChanges.pipe(debounceTime(250)).subscribe({
			next: () => {
				this.setNonStockedPOName();

				if (this.jobControl.value.trim() == '') {
					this.jobControl.setErrors({ minlength: true });
				}
			},
		});
	}

	public setNonStockedPOName() {
		let parts = this.nextPurchaseOrderName.split('-');

		this.orderForm.get('name').patchValue(`${parts[0]}-${this.jobControl.value}-${parts[1]}`);
	}

	public onFileChange(event) {
		if (event.target.files && event.target.files.length) {
			this.selectedFiles = event.target.files;
		}
	}

	public stockedChange(event) {
		// If stocked is changed to true then ensure fuel is switched to false
		if (event.checked) {
			this.orderForm.get('fuel').patchValue(false);
		}

		this.setNextPurchaseOrderNumber();
	}

	public fuelChange(event) {
		//	If fuel is changed to true then ensure stocked is switched to false
		if (event.checked) {
			this.orderForm.get('stocked').patchValue(false);
		}

		this.setNextPurchaseOrderNumber();
	}

	public nextPurchaseOrderName = null;
	public setNextPurchaseOrderNumber = () => {
		return this.api
			.getNextPurchaseOrderName()
			.toPromise()
			.then((result) => {
				let poName = result.nextPurchaseOrderName;

				if (this.orderForm.controls['stocked'].value == true || this.orderForm.controls['fuel'].value == true) {
					let parts = poName.split('-');

					return this.api
						.getVehicle(this.userService.getUser().vehicleId)
						.toPromise()
						.then((vehicle: Vehicle) => {
							poName = `${parts[0]}-${vehicle.unit_number}-${parts[1]}`;
							this.orderForm.controls.name.patchValue(poName);
						});
				} else {
					this.nextPurchaseOrderName = poName;
					this.setNonStockedPOName();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	public onPurchaseOrderFormSubmit = () => {
		let purchaseOrderSubmitted = Promise.resolve();

		if (!this.orderForm || this.orderForm.invalid || !this.selectedFiles || !this.selectedFiles.length) {
			this.orderForm.markAllAsTouched();
			return purchaseOrderSubmitted;
		}

		if (this.orderForm.controls.stocked.value == false && this.orderForm.controls.fuel.value == false && this.jobControl.value.trim().length == 0) {
			this.jobControl.markAllAsTouched();
			return purchaseOrderSubmitted;
		}

		return (purchaseOrderSubmitted = this.api
			.createPurchseOrder(this.orderForm.getRawValue())
			.toPromise()
			.then((result) => {
				//      Now we need to upload the selected files
				let formData: FormData = new FormData();

				formData.append('purchaseOrderId', result.id);
				for (var i = 0; i < this.selectedFiles.length; ++i) {
					formData.append('files', this.selectedFiles[i]);
				}

				return this.api
					.createFile(formData)
					.toPromise()
					.then((response) => {
						this.initializeOrderForm();
					})
					.then(() => {
						this.selectedFiles = [];
						SnackBarService.openSnackBarAlert('Purchase order succesfully created.');
					});
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');

				console.error(error);
			}));
	};
}
