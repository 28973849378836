import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { ConfirmModal } from '../confirm_modal/confirmModal';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'user-clock-in',
	templateUrl: './userClockIn.html',
	styleUrls: ['./userClockIn.scss'],
})
export class UserClockInComponent implements OnInit {
	public loading: boolean = false;
	public buttonText: string = 'Clock In';

	public status: any = {
		clockInTime: null,
		clockOutTime: null,
	};

	constructor(private userService: UserService, private api: ApiService, private dialog: MatDialog) {}

	public ngOnInit() {
		this.getStatusInformation();
	}

	public getStatusInformation() {
		this.api
			.getUserClockInStatus(this.userService.user.id)
			.toPromise()
			.then((response) => {
				this.status = response;
				this.userService.user.isClockedIn = response;
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				if (!this.status.clockInTime) {
					this.buttonText = 'Clock In';
				} else if (this.status.clockInTime && !this.status.clockOutTime) {
					this.buttonText = 'Clock Out';
				} else if (this.status.clockInTime && this.status.clockOutTime) {
					this.buttonText = 'All set for today!';
				}
			});
	}

	public clockInOut() {
		let componentRef = this.dialog.open(ConfirmModal);

		if (!this.status.clockInTime) {
			componentRef.componentInstance.dialogue = 'Are you sure you would like to clock in?';

			componentRef
				.afterClosed()
				.toPromise()
				.then((result) => {
					if (result) {
						this.api.userClockIn(this.userService.user.id).subscribe(
							(response) => {
								this.userService.user.isClockedIn = true;
								this.getStatusInformation();
							},
							(error) => console.error(error)
						);
					}
				});
		} else {
			componentRef.componentInstance.dialogue = 'Are you sure you would like to clock out?';

			componentRef
				.afterClosed()
				.toPromise()
				.then((result) => {
					if (result) {
						this.api.userClockOut(this.userService.user.id).subscribe(
							(response) => {
								this.userService.user.isClockedIn = true;
								this.getStatusInformation();
							},
							(error) => console.error(error)
						);
					}
				});
		}
	}
}
