<mat-form-field class="w-100">
	<mat-label>Store</mat-label>
	<input [readonly]="readonly" matInput placeholder="Store" [formControl]="control" [matAutocomplete]="auto" />
	<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
		@for (store of filteredStores | async; track store.id) {
		<mat-option [value]="store.id"> {{store.name}} </mat-option>
		}
	</mat-autocomplete>
	@for (validator of validators; track $index) {
	<mat-error>
		@if (formControlHasError(control, validator.key)) {
		<span [innerHTML]="validator.message"></span>
		}
	</mat-error>
	}
</mat-form-field>
