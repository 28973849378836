import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../entity_selector_table/entitySelectorTable';
import { Vehicle } from 'src/app/classes/vehicle';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
	templateUrl: './vehicles.html',
	styleUrls: ['./vehicles.scss'],
	selector: 'vehicles',
})
export class VehiclesComponent {
	public selectedVehicle: Vehicle = null;
	public tableDataSource = new MatTableDataSource();

	public showOnlyUserVehicles: boolean = true;
	public showOnlyFleetVehicles: boolean = false;
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	constructor(public userService: UserService, private api: ApiService, public loadingService: LoadingService) {
		this.loadVehicles();
		this.setupEntitySelectorTableDataSource();
	}

	public setupEntitySelectorTableDataSource() {
		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = '';
		editButton.columnProperty = 'edit';
		editButton.columnWidth = '60px';
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: 'edit',
			click: (vehicle: Vehicle) => {
				this.selectedVehicle = vehicle;
			},
		};
		this.selectorTableColumns.push(editButton);

		let unitNumberColumn = new EntitySelectorTableColumn();
		unitNumberColumn.columnHeader = 'Unit Number';
		unitNumberColumn.columnProperty = 'unit_number';
		unitNumberColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(unitNumberColumn);

		let vinColumn = new EntitySelectorTableColumn();
		vinColumn.columnHeader = 'VIN';
		vinColumn.columnProperty = 'vin';
		vinColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(vinColumn);

		let licenseColumn = new EntitySelectorTableColumn();
		licenseColumn.columnHeader = 'License Plate';
		licenseColumn.columnProperty = 'license_number';
		licenseColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(licenseColumn);

		this.tableDataSource.filterPredicate = (vehicle: Vehicle, filterText: string) => {
			let name = vehicle.unit_number + ' ' + vehicle.vin + ' ' + vehicle.license_number;
			if (name == null || name == undefined) name = '';

			return name.toLowerCase().indexOf(filterText.trim()) > -1;
		};
	}

	public backClicked() {
		this.selectedVehicle = null;
		//		Force filtering to reoccur
		this.tableDataSource.filter = this.tableDataSource.filter + ' ';
	}

	public onlyUserVehiclesChanged() {
		this.loadVehicles();
	}

	public onlyFleetVehiclesChanged() {
		this.loadVehicles();
	}

	public loadVehicles() {
		let vehiclesLoaded: Promise<any> = Promise.resolve();

		if (!this.showOnlyUserVehicles && !this.showOnlyFleetVehicles) {
			vehiclesLoaded = this.api.getAllVehicles().toPromise();
		} else {
			if (this.showOnlyFleetVehicles) {
				vehiclesLoaded = this.api
					.getAllVehicles()
					.toPromise()
					.then((vehicles) => {
						return vehicles.filter((vehicle) => vehicle.is_fleet_vehicle);
					});
			}

			//		This check needs to occur 2nd so that it overrides showOnlyFleetVehicles
			if (this.showOnlyUserVehicles) {
				vehiclesLoaded = Promise.all([this.api.getAllVehicles().toPromise(), this.api.getAllUsers().toPromise()]).then((results) => {
					let vehicles = results[0],
						users = results[1];

					return vehicles.filter((vehicle) => {
						return users.find((user) => user.vehicleId == vehicle.id);
					});
				});
			}
		}

		return vehiclesLoaded
			.then((results) => {
				let vehicles: Vehicle[] = results;

				if (!this.userService.isAdmin()) {
					vehicles = vehicles.filter((vehicle) => vehicle.id == this.userService.user.vehicleId);
				}

				if (vehicles && vehicles.length) {
					vehicles = vehicles.sort((a, b) => {
						let aVehicle = (a.unit_number && a.unit_number.toLowerCase()) || '';
						let bVehicle = (b.unit_number && b.unit_number.toLowerCase()) || '';

						if (aVehicle > bVehicle) return 1;
						if (aVehicle < bVehicle) return -1;
						return 0;
					});
				}

				this.tableDataSource.data = vehicles;

				if (this.selectedVehicle && this.selectedVehicle.id) {
					this.selectedVehicle = vehicles.find((vehicle) => vehicle.id == this.selectedVehicle.id);
				} else {
					this.selectedVehicle = null;
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	public newVehicleClicked() {
		this.selectedVehicle = new Vehicle();
	}
}
