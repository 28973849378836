import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PreventativeMaintenanceConfiguration, PreventativeMaintenanceRecord } from 'src/app/classes/preventative_maintenance';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../../entity_selector_table/entitySelectorTable';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { CustomerPicker } from '../../customers/customer_picker/customerPicker';
import { Subscription } from 'rxjs';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { DeviceService } from 'src/app/services/device.service';

@Component({
	selector: 'preventative-maintenance-selector',
	styleUrls: ['./selector.scss'],
	templateUrl: './selector.html',
})
export class PreventativeMaintenanceSelector implements OnInit, OnDestroy {
	public customerConfigurations: PreventativeMaintenanceConfiguration[] = [];
	public selectedConfigurationId: number = null;
	public tableDataSource = new MatTableDataSource();
	public customerId = null;
	public selectedVehicleId = null;

	public loading: boolean = false;
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	@ViewChild(MatSelect) configurationSelect: MatSelect;
	@ViewChild(CustomerPicker) customerPicker: CustomerPicker;

	private routeSubscription: Subscription = null;

	constructor(
		public userService: UserService,
		public api: ApiService,
		public datePipe: DatePipe,
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public device: DeviceService
	) {
		if (!this.routeSubscription) {
			this.routeSubscription = this.activatedRoute.params.subscribe(() => {
				if (this.activatedRoute.snapshot.queryParams['customerId']) {
					this.customerId = this.activatedRoute.snapshot.queryParams['customerId'];
					this.customerSelectedHandler({ option: { selected: true, value: this.customerId } } as any).then(() => {
						if (this.activatedRoute.snapshot.queryParams['configurationId']) {
							return this.onConfigurationChange({ value: this.activatedRoute.snapshot.queryParams['configurationId'] } as any);
						}
					});
				}
			});
		}
	}

	public ngOnDestroy() {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}

	public ngOnInit() {
		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = '';
		editButton.columnProperty = 'edit';
		editButton.columnWidth = '60px';
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: 'edit',
			click: (record: PreventativeMaintenanceRecord) => {
				if (record && record.id) {
					this.router.navigate(['record', record.id], { relativeTo: this.activatedRoute, queryParams: { isReadOnly: true } });
				}
			},
		};
		this.selectorTableColumns.push(editButton);

		let goToOrderButton = new EntitySelectorTableColumn();
		goToOrderButton.columnHeader = '';
		goToOrderButton.columnProperty = 'description';
		goToOrderButton.columnWidth = '60px';
		goToOrderButton.type = EntitySelectorTableColumnType.button;
		goToOrderButton.disabled = (record: PreventativeMaintenanceRecord) => {
			return !!!record.repair_order_id as boolean;
		};
		goToOrderButton.typeOptions = {
			icon: 'description',
			click: (record: PreventativeMaintenanceRecord) => {
				if (record && record.id && record.repair_order_id) {
					this.router.navigate(['home', 'repair_orders'], {
						queryParams: { id: record.repair_order_id },
					});
				}
			},
		};
		this.selectorTableColumns.push(goToOrderButton);

		let completeDate = new EntitySelectorTableColumn();
		completeDate.columnHeader = 'Completed Date';
		completeDate.columnProperty = 'completed';
		completeDate.type = EntitySelectorTableColumnType.data;
		completeDate.getData = (element) => {
			let date = '';

			if (element.completed) {
				date = this.datePipe.transform(new Date(element.completed), 'shortDate');
			}

			return date;
		};

		this.selectorTableColumns.push(completeDate);
		this.tableDataSource.filterPredicate = (record: PreventativeMaintenanceRecord, filterText: string) => {
			let name = '';
			if (name == null || name == undefined) name = '';

			return name.toLowerCase().indexOf(filterText.trim()) > -1;
		};
	}

	public editConfigurationClicked() {
		if (this.configurationSelect && this.configurationSelect.selected) {
			let selectedOption: MatOption = this.configurationSelect.selected as MatOption;

			if (selectedOption.value) {
				this.router.navigate(['configuration', selectedOption.value], { relativeTo: this.activatedRoute });
			}
		}
	}

	public onCustomerChange = (event) => {
		if (event.target.value == '') {
			this.customerConfigurations = [];
			this.tableDataSource.data = [];
		}
	};

	public onConfigurationChange(event: MatSelectChange): Promise<any> {
		let configurationId = event.value;

		if (configurationId) {
			this.selectedConfigurationId = Number(configurationId);

			return this.api
				.getConfigurationPreventativeMaintenanceRecords(configurationId)
				.toPromise()
				.then((records) => {
					this.tableDataSource.data = records;

					this.router.navigate([], {
						relativeTo: this.activatedRoute,
						queryParams: {
							configurationId: configurationId,
						},
						queryParamsHandling: 'merge', // preserve the existing query params in the route
						//skipLocationChange: true // do not trigger navigation
					});
				});
		} else {
			this.tableDataSource.data = [];
		}

		return Promise.resolve();
	}

	public createRecord() {
		let configuration = this.configurationSelect.selected as MatOption;

		if (configuration && configuration.value && this.selectedVehicleId) {
			this.router.navigate(['record', 'create', configuration.value], {
				relativeTo: this.activatedRoute,
				queryParams: { vehicleId: this.selectedVehicleId },
			});
		} else {
			SnackBarService.openSnackBarAlert('You must select a configuration and vehicle before a preventative maintenance record can be created.', 'orange');
		}
	}

	public createNewConfiguration() {
		if (this.customerPicker && this.customerPicker.customerId) {
			this.router.navigate(['configuration', 'create', this.customerPicker.customerId], { relativeTo: this.activatedRoute });
		} else {
			SnackBarService.openSnackBarAlert('You must select a customer before a configuration can be created.', 'orange');
		}
	}

	public customerSelectedHandler = (event: MatAutocompleteSelectedEvent) => {
		if (event && event.option.value && event.option.selected) {
			let customerId = event.option.value;

			return this.api
				.getCustomerPreventativeMaintenanceConfigurations(customerId)
				.toPromise()
				.then((configurations) => {
					this.customerConfigurations = configurations;
					this.tableDataSource.data = [];

					this.router.navigate([], {
						relativeTo: this.activatedRoute,
						queryParams: {
							customerId: customerId,
						},
						queryParamsHandling: 'merge', // preserve the existing query params in the route
						//skipLocationChange: true // do not trigger navigation
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}

		return Promise.resolve();
	};
}
