import { Injectable, OnInit } from '@angular/core';

const Themes: Theme[] = [
	{
		name: 'Teal / Ornage',
		class: 'teal-orange',
		isDark: false
	},
	{
		name: 'Deep Purple / Amber',
		class: 'deeppurple-amber',
		isDark: false
	},
	{
		name: 'Pink / Blue Grey',
		class: 'pink-bluegrey',
		isDark: true
	},
	{
		name: 'Purple / Green',
		class: 'purple-green',
		isDark: true
	},
];

export class Theme {
	name: string = 'Teal / Orange';
	class: string = 'teal-orange';
	isDark: boolean = false;
}

@Injectable({
	providedIn: 'root'
})
export class ThemeSwitcherService {
	public availableThemes: Theme[] = Themes;

	constructor() {
		try {
			//      See if a theme has been picked from the cache
			let theme: string = localStorage.getItem('theme') as any;

			if (!theme) {
				theme = JSON.stringify(this.availableThemes[0]) as any;
			}

			this.setTheme(JSON.parse(theme));
		} catch (error) {
			this.setTheme(this.availableThemes[0]);
		}
	}

	public setTheme(theme: Theme) {
		let body = document.getElementsByTagName('body')[0] as HTMLBodyElement;
		body.classList.replace(body.classList.item(0) as string, theme.class);

		localStorage.setItem('theme', JSON.stringify(theme));
	}

	public getDarkThemes(): Theme[] {
		return this.availableThemes.filter(theme => theme.isDark);
	}

	public getLightThemes(): Theme[] {
		return this.availableThemes.filter(theme => !theme.isDark);
	}

}
