export class PurchaseOrder {
	public id: number;
	public name: string;
	public createdAt: Date | string;
	public user_id: number;
	public store: string;
	public total_cost: number;
	public products: Array<PurchaseOrderProduct>;
	public purchaseOrderProcessingStepId: Stage;
	public photos: Array<string>;
	public store_id: number;
	public stocked: boolean;
	public fuel: boolean;

	constructor() {}
}

export class PurchaseOrderProduct {
	public id: number;
	public productId: number;
	public purchaseOrderId: number;
	public part_number: string;
	public quantity: number;
	public description: string;
	public cost: number;

	constructor() {}
}

export enum Stage {
	New = 1,
	InReceiving = 2,
	Closed = 3,
}
