import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Decimal from 'decimal.js';
import { Product } from 'src/app/classes/product';
import { ApiService } from 'src/app/services/api.service';
import { trimToNumber, trimToString } from 'src/app/services/Helper';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { MatSelectChange } from '@angular/material/select';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { LocalStorageService } from 'src/app/services/local_storage.service';

export class VehicleInventorySnapshot {
	id: number;
	createdAt: string;
	updatedAt: string;
	snapshot: InventoryProduct[];
	vehicleId: number;
}

class InventoryProduct extends Product {
	inventoried: boolean;
	last_inventory_date: string; // Date

	constructor(data?: Product | any) {
		// Add data as a parameter
		super(data); // Call the super constructor with the data parameter
		this.inventoried = false;

		// Set the default value for last_inventory_date to null
		this.last_inventory_date = data?.last_inventory_date || null;
	}
}

@Component({
	selector: 'vehicle-inventory',
	template: `
		<div mat-dialog-title>
			<div class="flex-container flex-container-column flex-gap flex-justify-content-left">
				<div><h1>Vehicle Inventory Snapshots</h1></div>

				<div class="flex-container flex-container-row flex-gap">
					<div>
						<mat-form-field>
							<mat-label>Snapshot</mat-label>
							<mat-select (selectionChange)="onSnapshotChanged($event)" [(ngModel)]="selectedSnapshot" class="form-control w-100">
								<mat-option [value]="0" selected>New Snapshot</mat-option>
								@for(snapshot of snapshots; track snapshot) {
								<mat-option [value]="snapshot">{{ snapshot.createdAt | date : 'mediumDate' }}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</div>

					<span style="width: 100px"><p style="font-style: italics">Compared To</p></span>

					<div>
						<mat-form-field>
							<mat-label>Snapshot</mat-label>
							<mat-select (selectionChange)="onComparisonSelected($event)" [(ngModel)]="selectedComparison" class="form-control w-100">
								<mat-option [value]="null" selected>Select Comparison...</mat-option>
								@for(snapshot of snapshots; track snapshot) {
								<mat-option [value]="snapshot">{{ snapshot.createdAt | date : 'mediumDate' }}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</div>
				</div>

				<div class="flex-container flex-container-row flex-gap flex-justify-content-left mt-3">
					<div>
						<strong>Total Cost: {{ totalCost | currency }}</strong>
					</div>

					@if(this.selectedComparison) {
					<div>
						<strong>Compared Cost: {{ comparedCost | currency }}</strong>
					</div>

					@if(!this.isShowingDiffedInventory) {
					<div>
						<button mat-raised-button color="primary" (click)="showDiffedInventory()">Show Inventory Difference</button>
					</div>
					} @if(this.isShowingDiffedInventory) {
					<div>
						<button mat-raised-button color="primary" (click)="closeDiffedInventory()">Close Difference</button>
					</div>
					} }
				</div>

				<div class="flex-container flex-gap flex-container-row flex-justify-content-left mt-3">
					<mat-form-field>
						<mat-label>Filter</mat-label>
						<input matInput [formControl]="vehicleFilterControl" placeholder="Filter" />
					</mat-form-field>
				</div>
			</div>
		</div>

		<div mat-dialog-content>
			<div class="flex-container flex-container-column flex-gap flex-align-items-left">
				<div style="max-height: 450px;" class="overflow-auto">
					<table id="mat-table" mat-table [dataSource]="displayedProducts" class="mat-elevation-z8">
						<!--- Note that these columns can be defined in any order.
	  					The actual rendered columns are set as a property on the row definition" -->

						@for(columnDef of displayedColumns; track columnDef) {
						<ng-container matColumnDef="{{ columnDef.name }}">
							<th mat-header-cell *matHeaderCellDef>{{ columnDef.name }}</th>
							@if(columnDef.name === 'Inventoried') {
							<td mat-cell *matCellDef="let element">
								<mat-slide-toggle
									[disabled]="selectedSnapshot != 0"
									(change)="rowInventoriedChange($event)"
									[(ngModel)]="element[columnDef.data_key]"></mat-slide-toggle>
							</td>
							} @else if(columnDef.name === 'Cost' || columnDef.name === 'Quantity') {
							<td mat-cell *matCellDef="let element">
								<mat-form-field style="margin-top: 20px">
									<mat-label>{{ columnDef.name }}</mat-label>
									<input (change)="updateLocalCache()" matInput type="number" [(ngModel)]="element[columnDef.data_key]" />
								</mat-form-field>
							</td>
							} @else if(columnDef.name === "Part Number" || columnDef.name == 'Description') {
							<td mat-cell *matCellDef="let element">
								<mat-form-field style="margin-top: 20px">
									<mat-label>{{ columnDef.name }}</mat-label>
									<input
										(change)="updateLocalCache()"
										[disabled]="element.id && !this.userService.isAdmin()"
										matInput
										type="text"
										[(ngModel)]="element[columnDef.data_key]" />
								</mat-form-field>
							</td>
							} @else if(columnDef.name == "Last Inventory Date") {
							<td mat-cell *matCellDef="let element">{{ element[columnDef.data_key] | date }}</td>
							} @else {
							<td mat-cell *matCellDef="let element">{{ element[columnDef.data_key] }}</td>
							}
						</ng-container>
						}

						<tr mat-header-row *matHeaderRowDef="getDisplayNames()"></tr>
						<tr mat-row *matRowDef="let row; columns: getDisplayNames()"></tr>
					</table>
				</div>
			</div>
		</div>
		<div mat-dialog-actions>
			<div class="flex-container flex-container-column flex-gap flex-justify-content-left">
				<div class="flex-container flex-container-row flex-gap">
					<mat-paginator
						showFirstLastButtons
						[length]="displayedProducts ? displayedProducts.data.length : 0"
						[pageSize]="10"
						[pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

					@if(this.selectedSnapshot == 0) {
					<mat-slide-toggle
						style="margin-top: 15px"
						(change)="onShowOnlyNonInventoriedProductsChanged($event)"
						[(ngModel)]="showOnlyNonInventoriedProdcuts"
						>Show Only Non-Inventoried Products</mat-slide-toggle
					>
					}
				</div>

				<div class="flex-container flex-container-column flex-gap flex-justify-content-left">
					<div class="flex-container flex-container-row flex-gap flex-justify-content-left">
						@if(this.selectedSnapshot == 0) {
						<loading-button [materialColor]="'primary'" [action]="submitNewSnapshot" [text]="'Save New Inventory'"></loading-button>
						}

						<button mat-raised-button color="primary" (click)="closeDialog()">Close</button>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: `
		::-webkit-scrollbar {
			width: 0px;
			background: transparent;
			/* make scrollbar transparent */
		}
	`,
})
export class VehicleInventory implements OnInit, AfterViewInit {
	@Input('vehicleId') vehicleId: number = null;
	@Output() inventoryUpdated = new EventEmitter();

	public snapshots: VehicleInventorySnapshot[] = [];
	public selectedSnapshot: VehicleInventorySnapshot | number = 0;
	public selectedComparison: VehicleInventorySnapshot = null;
	public displayedColumns: { name: string; data_key: string }[] = [
		{ name: 'Inventoried', data_key: 'inventoried' },
		{ name: 'Part Number', data_key: 'part_number' },
		{ name: 'Cost', data_key: 'cost' },
		{ name: 'Quantity', data_key: 'quantity' },
		{ name: 'Description', data_key: 'description' },
		{ name: 'Last Inventory Date', data_key: 'last_inventory_date' },
	];
	public totalCost = 0;
	public comparedCost = 0;
	public showOnlyNonInventoriedProdcuts = true;

	public vehicleFilterControl = new FormControl('');

	@ViewChild(MatPaginator) paginator: MatPaginator;

	public displayedProducts: MatTableDataSource<InventoryProduct> = null;
	public vehicleProducts: InventoryProduct[] = null;

	constructor(
		private api: ApiService,
		public dialogRef: MatDialogRef<VehicleInventory>,
		public dialog: MatDialog,
		public userService: UserService,
		private localStorage: LocalStorageService
	) {}

	public ngOnInit() {}

	ngAfterViewInit() {
		this.initialize();
	}

	// This code initializes the component by getting all the snapshots for the vehicle and setting them to the snapshots array. It also sets the selectedSnapshot to null and
	// initializes the allProducts array. It then calls the calculateTotalCost function to calculate the total cost of all the products in the selected snapshot.
	public initialize() {
		let cachedItemHandled = Promise.resolve();
		this.vehicleProducts = null;

		if (this.localStorage.has(this.storageKey, this.vehicleId)) {
			const confirmationDialog = this.dialog.open(ConfirmModal);
			confirmationDialog.componentInstance.dialogue =
				'You appear to have local unsaved changes from a previous inventory session. Would you like to load them?';

			cachedItemHandled = confirmationDialog
				.afterClosed()
				.toPromise()
				.then((result) => {
					if (result) {
						this.vehicleProducts = JSON.parse(this.localStorage.get(this.storageKey, this.vehicleId));
					}
				});
		}

		cachedItemHandled
			.then(() => {
				this.vehicleFilterControl.valueChanges.pipe(debounceTime(250)).subscribe({
					next: (value) => {
						if (!value || value.length <= 0) {
							value = ' ';
						}

						this.displayedProducts.filter = value;
					},
				});

				this.api.getVehicleInventorySnapshots(this.vehicleId).subscribe(
					(snapshots: VehicleInventorySnapshot[]) => {
						this.snapshots = snapshots;
					},
					(error) => {
						console.error(error);
					}
				);

				if (this.vehicleProducts == null) {
					return this.api
						.getVehicleProducts(this.vehicleId, false)
						.toPromise()
						.then((vehicleProducts: InventoryProduct[]) => {
							this.vehicleProducts = vehicleProducts.map((vehicleProduct) => {
								let product = new InventoryProduct(vehicleProduct);

								return product;
							});
						});
				}
			})
			.then(() => {
				this.displayedProducts = new MatTableDataSource<InventoryProduct>(this.vehicleProducts);
				this.displayedProducts.paginator = this.paginator;

				//	Set a custom filter predicate for the displayedProducts data source
				this.displayedProducts.filterPredicate = (data: InventoryProduct, filter: string) => {
					let filterText = filter.toLowerCase().trim(); // Convert the filter text to lowercase

					const textMatch = data.part_number?.toLowerCase().includes(filterText) || data.description?.toLowerCase().includes(filterText);

					if (this.selectedSnapshot == 0) {
						return textMatch && (this.showOnlyNonInventoriedProdcuts && data.inventoried ? false : true);
					}

					return textMatch;
				};

				this.displayedProducts.filter = ' ';

				this.calculateTotalCost();
				this.setDisplayedDataToVehicleProducts();
			});
	}

	public storageKey = 'vehicle_inventory_cache';
	public updateLocalCache() {
		//	Make sure we only cache "new" snap shots
		if (this.selectedSnapshot == 0) {
			this.localStorage.add(this.storageKey, this.vehicleId, this.displayedProducts.data);
		}
	}

	public rowInventoriedChange(checked: boolean) {
		this.updateLocalCache();

		//		Delay updating the rows to create a debounce effect for the table
		setTimeout(() => {
			const originalFilter = this.displayedProducts.filter;
			this.displayedProducts.filter = ' ';
			this.displayedProducts.filter = originalFilter;
		}, 300);
	}

	public onShowOnlyNonInventoriedProductsChanged = (event) => {
		this.setDisplayedDataToVehicleProducts();
	};

	public setDisplayedDataToVehicleProducts(): void {
		this.displayedProducts.data = this.vehicleProducts;
	}

	public setDisplayedDataFromSelectedSnapshot(): void {
		this.displayedProducts.data = (this.selectedSnapshot as VehicleInventorySnapshot).snapshot;
	}

	public getDisplayNames() {
		return this.displayedColumns.map((column) => column.name);
	}

	// This function calculates the total cost of all the products in the selected snapshot by
	// looping through each product and multiplying the cost by the quantity. It then sets
	// the totalCost variable to the calculated value. If there is no selected snapshot, it sets
	// the totalCost to 0. It also trims the totalCost to 2 decimal places. Finally, it returns
	// the totalCost. This function is called in the onSnapshotChanged function and the
	// initialize function. It is also called in the deleteSelectedSnapshot function after the
	// snapshot is deleted. This ensures that the total cost is always up to date.
	public calculateTotalCost() {
		this.totalCost = 0;

		let products = this.selectedSnapshot != 0 ? (this.selectedSnapshot as VehicleInventorySnapshot).snapshot : this.displayedProducts.data;

		//		Calculate the amount of money on the vehicle
		products.map((product) => {
			let cost = new Decimal(trimToNumber(product.cost || 0, 2));
			let quantity = new Decimal(trimToNumber(product.quantity || 0, 2));

			this.totalCost += cost.times(quantity).toNumber();
		});

		this.totalCost = trimToNumber(this.totalCost, 2);
	}

	public onSnapshotChanged(event) {
		//	If we are not displaying a new snapshot
		if (event.value != 0) {
			this.setDisplayedDataFromSelectedSnapshot();
		} else {
			this.setDisplayedDataToVehicleProducts();
		}

		this.calculateTotalCost();

		if (this.selectedComparison) {
			this.onComparisonSelected({ value: this.selectedComparison, source: null });
		}

		if (this.isShowingDiffedInventory) {
			this.closeDiffedInventory();
		}
	}

	public deleteSelectedSnapshot = () => {
		if (this.selectedSnapshot) {
			const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
			return dialogRef
				.afterClosed()
				.toPromise()
				.then((result) => {
					if (result) {
						return this.api
							.deleteVehicleInventorySnapshot(this.vehicleId, (this.selectedSnapshot as VehicleInventorySnapshot).id)
							.toPromise()
							.finally(() => {
								this.initialize();
							});
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	public submitNewSnapshot = () => {
		//	Only allow new snapshots to be submitted
		if (this.vehicleId && (this.selectedSnapshot as any) == 0) {
			// Before allowing the save to go through, check and see if each product has inventored value flipped to true
			// If a product is foudn without the inventoried flag flipped to true then display a snack bar message that they need to
			// ensure all items are marked as inventoried. If all items are inventoried then proceed with the save.
			let non_inventoried_products = this.displayedProducts.data.filter((product) => !product.inventoried);
			if (non_inventoried_products.length > 0) {
				SnackBarService.openSnackBarAlert('Please ensure all products are inventoried before saving', 'red');
				return Promise.resolve();
			}

			return this.api
				.snapshotVehicleInventory(this.vehicleId, this.displayedProducts.data) // Call the postVehicleInventorySnapshot function with the vehicleId and snapshot
				.toPromise() // Convert the observable to a promise
				.then(() => {
					SnackBarService.openSnackBarAlert('Vehicle inventory snapshot successfully saved');

					// Alert any listeners that this vehicles inventory has been updated
					this.inventoryUpdated.emit();

					//	Clear the local storage cache since the inventory has now been saved
					this.localStorage.remove(this.storageKey, this.vehicleId);

					this.initialize();
				})
				.catch((error) => {
					SnackBarService.openSnackBarAlert('Sorry, an error occurred while creating your vehicle inventory snapshot', 'red');
					console.error(error); // Log the error to the console
				});
		}

		return Promise.resolve();
	};

	public closeDialog() {
		const dialogRef = this.dialog.open(ConfirmModal);
		dialogRef.componentInstance.dialogue = 'Are you sure you would like to do that?';

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				dialogRef.close();
				this.dialogRef.close();
			}
		});
	}

	public isShowingDiffedInventory: boolean = false;
	public showDiffedInventory() {
		let diffedInventory = new MatTableDataSource<InventoryProduct>();

		let products = this.displayedProducts.data;

		products.forEach((product) => {
			let comparisonProduct = this.selectedComparison.snapshot.find((comparisonProduct) => comparisonProduct.id === product.id);
			if (comparisonProduct) {
				if (product.cost !== comparisonProduct.cost || product.quantity !== comparisonProduct.quantity) {
					diffedInventory.data.push(product);
				}
			}

			diffedInventory.paginator = this.paginator;
		});

		this.displayedProducts = diffedInventory;
		this.isShowingDiffedInventory = true;
	}

	public closeDiffedInventory() {
		if (this.selectedSnapshot != 0) {
			this.setDisplayedDataFromSelectedSnapshot();
		} else {
			this.setDisplayedDataToVehicleProducts();
		}

		this.isShowingDiffedInventory = false;
	}

	/**
	 * Calculate the total cost difference between all products and the selected comparison snapshot data.
	 *
	 * This method iterates through each product in the allProducts data source, finds the corresponding product in the selected comparison snapshot data,
	 * and calculates the difference in cost and quantity between the two products. The calculated cost difference is accumulated to determine the total compared cost.
	 *
	 * @param event The event triggering the comparison selection.
	 */
	public onComparisonSelected(event: MatSelectChange) {
		if (event.value == null) {
			this.comparedCost = 0;

			return;
		}

		// Calculate the total cost different between all products and the selected comparison snapshot data
		this.comparedCost = 0;

		// Loop through each product in the allProducts data source
		this.displayedProducts.data.forEach((product) => {
			// Find the product in the selected comparison snapshot data
			let comparisonProduct = this.selectedComparison.snapshot.find((comparisonProduct) => comparisonProduct.id === product.id);

			// If the product is found
			if (comparisonProduct) {
				// Calculate the difference between the cost and quantity of the product in the all
				// products data source and the cost and quantity of the product in the selected
				// comparison snapshot data
				let costDifference = new Decimal(trimToString((product.cost || 0) * (product.quantity || 0), 2));
				costDifference = costDifference.minus(new Decimal(trimToString((comparisonProduct.cost || 0) * (comparisonProduct.quantity || 0), 2)));

				this.comparedCost += costDifference.toNumber();
			}
		});
	}
}
