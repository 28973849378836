import { Injectable } from '@angular/core';

import { PreventativeMaintenanceRecordComponent } from '../components/preventative_maintenance/record_component/record.component';
import { ConfirmModal } from '../components/confirm_modal/confirmModal';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root'
})
export class PreventativeMaintenanceRecordCanDeactivateGuard {

	constructor(private dialog: MatDialog) { }

	public canDeactivate(recordComponent: PreventativeMaintenanceRecordComponent): Promise<boolean> {
		if (!recordComponent.record.id) {
			return new Promise((resolve, reject) => {
				let componentRef = this.dialog.open(ConfirmModal);
				componentRef.componentInstance.dialogue = 'Are you sure you want to go leave this page? Any unsaved changes will be lost.';

				componentRef.afterClosed().toPromise().then(result => {
					if (result)
						resolve(true);

					resolve(false);
				}).catch(error => resolve(false));
			});
		} else {
			return Promise.resolve(true);
		}
	}
}