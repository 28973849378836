import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class NetworkService implements OnDestroy {
	public onlineEvent: Observable<Event>;
	public offlineEvent: Observable<Event>;
	public subscriptions: Subscription[] = [];
	public isOnline:boolean = true;
	
	public constructor() {
		this.onlineEvent = fromEvent(window, 'online');
		this.offlineEvent = fromEvent(window, 'offline');
	
		this.subscriptions.push(this.onlineEvent.subscribe(e => {
			this.isOnline = true;
		}));
	
		this.subscriptions.push(this.offlineEvent.subscribe(e => {
			this.isOnline = false;
		}));
	}

	public ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public watchForNetworkChanges(listener:any) {
		if(listener) {
			this.subscriptions.push(
				this.onlineEvent.pipe(map(event => this.isOnline)).subscribe(listener),
				this.offlineEvent.pipe(map(event => this.isOnline)).subscribe(listener)
			);

		}
	}
}