import { Directive, ElementRef, HostListener } from '@angular/core';
import { ConfirmModal } from '../components/confirm_modal/confirmModal';
import { MatDialog } from '@angular/material/dialog';

@Directive({
	selector: '[downloadOnClick]'
})
export class ClickToDownload {
	constructor(public elementRef: ElementRef, private dialog: MatDialog) { }

	@HostListener('click')
	public onClick() {

		let componentRef = this.dialog.open(ConfirmModal);
		componentRef.componentInstance.dialogue = 'Would you like to download this photo?';

		return componentRef.afterClosed().toPromise().then(result => {
			if (result) {
				let image = this.elementRef.nativeElement;

				let link = document.createElement('a');
				link.href = image.src;
				link.download = 'its_photo';
				link.click();
				link = null;

				URL.revokeObjectURL(image.src);
			}
		});
	}

	public dataURItoBlob(dataURI): Blob {
		// convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		var byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

		// write the bytes of the string to an ArrayBuffer
		var ab = new ArrayBuffer(byteString.length);

		// create a view into the buffer
		var ia = new Uint8Array(ab);

		// set the bytes of the buffer to the correct values
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		var blob = new Blob([ab], { type: mimeString });
		return blob;

	}
}