import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { User } from 'src/app/classes/user';
import { AuthorizationService } from 'src/app/modules/google/google.module';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { DeviceService } from 'src/app/services/device.service';

@Component({
	templateUrl: './user.html',
	styleUrls: ['./user.scss'],
	selector: 'user',
})
export class UserComponent extends FormComponent implements OnInit {
	public userForm: UntypedFormGroup = null;

	_user: User = null;
	get user(): User {
		return this._user;
	}

	@Input()
	set user(value: User) {
		if (value) {
			//      Create copy of the order and use it for editing
			this._user = JSON.parse(JSON.stringify(value));

			//      Create our form group instance
			this.initializeUserForm();
		}
	}

	@Output('onRefresh')
	onRefresh = new EventEmitter<any>();

	constructor(
		public userService: UserService,
		private api: ApiService,
		private formBuilder: UntypedFormBuilder,
		private googleAuth: AuthorizationService,
		public dialog: MatDialog,
		public device: DeviceService
	) {
		super();
	}

	public ngOnInit() {
		this.initializeUserForm();
		this.getUserTypes();
	}

	public initializeUserForm() {
		if (this.user) {
			this.userForm = this.formBuilder.group({
				id: new FormControl(this.user.id),
				firstname: new FormControl({ value: this.user.firstname, disabled: !this.userService.isAdmin() }, [Validators.required]),
				lastname: new FormControl({ value: this.user.lastname, disabled: !this.userService.isAdmin() }, [Validators.required]),
				username: new FormControl({ value: this.user.username, disabled: !this.userService.isAdmin() }, Validators.required),
				vehicleId: new FormControl({ value: this.user.vehicleId, disabled: !this.userService.isAdmin() }, Validators.required),
				email: new FormControl(this.user.email, [Validators.required, Validators.email]),
				mobile_phone: new FormControl(this.user.mobile_phone),
				employee_number: new FormControl({ value: this.user.employee_number, disabled: !this.userService.isAdmin() }, Validators.required),
				active: new FormControl({ value: this.user.active ? true : false, disabled: !this.userService.isAdmin() }),
				user_type_id: new FormControl({ value: this.user.user_type_id, disabled: !this.userService.isAdmin() }, [Validators.required]),
				changedPasswordControl: new FormControl(null, [this.passwordsMatchValidator]),
				changedPasswordCopyControl: new FormControl(null, [this.passwordsMatchValidator]),
			});
		}
	}

	public onUserFormSubmit = () => {
		//	Promise to be returned
		let userSubmitted = Promise.resolve();

		if (!this.userForm || this.userForm.invalid) {
			this.userForm.markAllAsTouched();
			return userSubmitted;
		}

		if (this.userForm.value.id) {
			userSubmitted = this.api.updateUser(this.userForm.value).toPromise();
		} else {
			userSubmitted = this.api.createUser(this.userForm.value).toPromise();
		}

		return userSubmitted
			.then(() => {
				SnackBarService.openSnackBarAlert('User saved.');
				this.onRefresh.emit();
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	};

	public userTypes = [];
	public getUserTypes() {
		return this.api
			.getUserTypes()
			.toPromise()
			.then((types) => {
				this.userTypes = types;
			})
			.catch((error) => {
				console.error(error);
			});
	}

	public passwordsMatchValidator(control: UntypedFormControl) {
		if (!control.parent) return null;

		let changedPassword = control.parent.get('changedPasswordControl'),
			changedPasswordCopy = control.parent.get('changedPasswordCopyControl');

		if (changedPassword.value == '' && changedPasswordCopy.value == '') return null;

		let isObjectEmpty = (object) => {
			if (object) {
				let keys = Object.keys(object).length;

				if (keys <= 0) return true;
				else return false;
			}

			return true;
		};

		if (changedPassword && changedPasswordCopy) {
			if (changedPassword.value != changedPasswordCopy.value) {
				let changedPasswordErrors = changedPassword.errors || {},
					changedPasswordCopyErrors = changedPasswordCopy.errors || {};

				changedPasswordErrors.mismatch = true;
				changedPasswordCopyErrors.mismatch = true;

				if (control == changedPassword) changedPasswordCopy.setErrors(changedPasswordCopyErrors, { emitEvent: false });
				else changedPassword.setErrors(changedPasswordErrors, { emitEvent: false });

				return changedPasswordErrors;
			} else {
				let changedPasswordErrors = changedPassword.errors || null,
					changedPasswordCopyErrors = changedPasswordCopy.errors || null;

				if (changedPasswordErrors && changedPasswordErrors.hasOwnProperty('mismatch')) delete changedPasswordErrors.mismatch;

				if (changedPasswordCopyErrors && changedPasswordCopyErrors.hasOwnProperty('mismatch')) delete changedPasswordCopyErrors.mismatch;

				if (isObjectEmpty(changedPasswordCopyErrors)) changedPasswordCopyErrors = null;
				if (isObjectEmpty(changedPasswordErrors)) changedPasswordErrors = null;

				if (control == changedPassword) changedPasswordCopy.setErrors(changedPasswordCopyErrors, { emitEvent: false });
				else changedPassword.setErrors(changedPasswordErrors, { emitEvent: false });

				return changedPasswordErrors;
			}
		} else return null;
	}

	public deactivateAccount = () => {
		if (!this.user.id) {
			return Promise.resolve();
		}

		const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
		return dialogRef
			.afterClosed()
			.toPromise()
			.then((result) => {
				if (result) {
					return this.api
						.deactivateUser(this.user.id)
						.toPromise()
						.then(() => {
							SnackBarService.openSnackBarAlert('User deactivated.');
							this.onRefresh.emit();
						});
				}
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	};

	public quickResetUserPassword = () => {
		if (!this.userService.isAdmin()) {
			SnackBarService.openSnackBarAlert('Insufficient permissions to reset password', 'red');
			return Promise.resolve();
		}

		const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
		return dialogRef
			.afterClosed()
			.toPromise()
			.then((result) => {
				if (result) {
					return this.api
						.resetUserPassword(this.user.id.toString())
						.toPromise()
						.then(() => {
							SnackBarService.openSnackBarAlert(
								'Password reset to users username. Please have them reset their password as soon as possible',
								'green'
							);
						})
						.catch((error) => {
							SnackBarService.openSnackBarAlert(error, 'red');
						});
				}
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	};
}
