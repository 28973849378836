@if (storeForm) {
<div class="mt-3 flex-container flex-container-column flex-justify-center">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>
				<span> @if (!store.id) { New Store } @else { Store } </span>
			</h2>
		</mat-card-title>

		<mat-card-content>
			<div>
				<form [formGroup]="storeForm">
					<div>
						<mat-form-field class="w-100">
							<mat-label>Name</mat-label>
							<input formControlName="name" matInput type="text" />
							@if (formGroupHasError(storeForm, 'name', 'required')) {
							<mat-error> Name is <strong>required</strong> </mat-error>
							}
						</mat-form-field>
					</div>
				</form>
			</div>
			<br />

			<mat-divider></mat-divider>

			<br />

			<div>
				<loading-button [text]="'Submit'" [action]="onStoreFormSubmit"></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
}
