<mat-form-field class="w-100">
	<mat-label>User</mat-label>
	<input [(ngModel)]="userId" matInput placeholder="User" [formControl]="control" [matAutocomplete]="auto" />
	<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
		@for(user of filteredUsers | async; track user.id) {
		<mat-option [value]="user.id"> {{user.firstname }} {{ user.lastname }} - {{ user.employee_number }} </mat-option>
		}
	</mat-autocomplete>

	@for(validator of validators; track $index) {
	<mat-error>
		@if(formControlHasError(control, validator.key)) {
		<span [innerHTML]="validator.message"></span>
		}
	</mat-error>
	}
</mat-form-field>
