import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/loginComponent';
import { HomeComponent } from './components/home/homeComponent';
import { UsersComponent } from './components/users/users';
import { PageNotFoundComponent } from './components/page_not_found/pageNotFoundComponent';
import { InvoicesComponent } from './components/invoices/invoicesComponent';
import { RepairOrdersComponent } from './components/repair_orders/repairOrdersComponent';
import { CustomersComponent } from './components/customers/customersComponent';
import { SettingsComponent } from './components/settings/settings.component';
import { VehiclesComponent } from './components/vehicles/vehiclesComponent';
import { ReceivingComponent } from './components/purchase_orders/receiving/receiving.component';
import { RepairOrderCanDeactivateGuard } from './route_guards/repair_order/repairOrderCanDeactivateGuard';
import { PayrollComponent } from './components/payroll/payroll.component';
import { StoresComponent } from './components/stores/stores.component';
import { ReportsComponent } from './components/reports/reports.component';
import { NewPurchaseOrderComponent } from './components/purchase_orders/purchase_order/purchaseOrder.component';
import { isAdminGuard } from './route_guards/isAdmin';
import { PreventativeMaintenancePage } from './components/preventative_maintenance/preventative_maintenance_page/page';
import { PreventativeMaintenanceRecordComponent } from './components/preventative_maintenance/record_component/record.component';
import { PreventativeMaintenanceConfigurationComponent } from './components/preventative_maintenance/configuration_component/configuration.component';
import { PreventativeMaintenanceSelector } from './components/preventative_maintenance/preventative_maintenance_page/selector';
import { PreventativeMaintenanceRecordCanDeactivateGuard } from './route_guards/preventative_maintenance_deactivate.guard';
import { Quotes } from './components/qoutes/quotes';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{
		path: 'home',
		component: HomeComponent,
		children: [
			{ path: 'accounts', component: UsersComponent },
			{ path: 'new_purchase_order', component: NewPurchaseOrderComponent },
			{ path: 'receiving', component: ReceivingComponent },
			{ path: 'quotes', component: Quotes },
			{ path: 'repair_orders', component: RepairOrdersComponent, canDeactivate: [RepairOrderCanDeactivateGuard] },
			{ path: 'invoices', component: InvoicesComponent },
			{ path: 'customers', component: CustomersComponent },
			{ path: 'vehicles', component: VehiclesComponent },
			{ path: 'stores', component: StoresComponent },
			{ path: 'reporting', component: ReportsComponent, canActivate: [isAdminGuard] },
			{ path: 'settings', component: SettingsComponent, canActivate: [isAdminGuard] },
			{ path: 'payroll', component: PayrollComponent },
			{
				path: 'preventative_maintenance',
				component: PreventativeMaintenancePage,
				children: [
					{ path: 'selector', component: PreventativeMaintenanceSelector },
					{ path: 'selector/configuration/:configurationId', component: PreventativeMaintenanceConfigurationComponent },
					{ path: 'selector/record/:recordId', component: PreventativeMaintenanceRecordComponent },
					{
						path: 'selector/record/create/:configurationId',
						component: PreventativeMaintenanceRecordComponent,
						canDeactivate: [PreventativeMaintenanceRecordCanDeactivateGuard],
					},
					{ path: 'selector/configuration/create/:customerId', component: PreventativeMaintenanceConfigurationComponent },
				],
			},
		],
	},
	{ path: '**', component: PageNotFoundComponent }, //      This route is last and displayed if the requested route is not found
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
	providers: [RepairOrderCanDeactivateGuard],
})
export class AppRoutingModule {}
