import { Component, Input, OnInit, HostListener } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

/**
 * LoadingButton Component
 *
 * This component represents a button that shows a loading state when an action is being performed.
 * It integrates with the `LoadingService` to manage the loading state across the application.
 *
 * @component
 * @selector loading-button
 * @templateUrl ./loadingButton.html
 * @styleUrls ./loadingButton.scss
 *
 * @property {string} text - The text to display on the button.
 * @property {string} buttonClass - The CSS class to apply to the button.
 * @property {string} iconClass - The CSS class to apply to the icon within the button.
 * @property {Function} action - The function to execute when the button is clicked.
 * @property {boolean | Function} disabled - A boolean or function that determines if the button is disabled.
 * @property {any} actionArgs - The arguments to pass to the action function.
 * @property {boolean} runOnEnter - If true, the action will be triggered when the Enter key is pressed.
 * @property {string} materialType - The type of material button to use (default is 'mat-raised-button').
 * @property {string} materialColor - The color of the material button (default is 'accent').
 *
 * @method handleKeyboardEvent - Listens for the Enter key press and triggers the action if `runOnEnter` is true.
 * @param {KeyboardEvent} event - The keyboard event.
 *
 * @method clicked - Executes the action and manages the loading state.
 *
 * @method getDisabled - Determines if the button is disabled based on the `disabled` property.
 * @returns {boolean} - True if the button is disabled, false otherwise.
 *
 * @constructor
 * @param {LoadingService} loadingService - The service to manage the loading state.
 */
@Component({
	selector: 'loading-button',
	templateUrl: './loadingButton.html',
	styleUrls: ['./loadingButton.scss'],
})
export class LoadingButton {
	@Input('text') text: string;
	@Input('buttonClass') buttonClass: string;
	@Input('iconClass') iconClass: string;
	@Input('action') action: any;
	@Input('disabled') disabled: any = false;
	@Input('actionArgs') actionArgs: any;
	@Input() runOnEnter: boolean = false;
	@Input() materialType: string = 'mat-raised-button';
	@Input() materialColor: string = 'accent';

	public loading: boolean = false;

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (this.runOnEnter && event.code == 'Enter') {
			this.clicked();
		}
	}

	constructor(public loadingService: LoadingService) {}

	public clicked() {
		if (this.loading) {
			return;
		}

		this.loading = true;

		if (Array.isArray(this.actionArgs)) {
			this.action(...this.actionArgs).finally(() => {
				this.loading = false;
			});
		} else {
			this.action(this.actionArgs).finally(() => {
				this.loading = false;
			});
		}
	}

	public getDisabled() {
		if (typeof this.disabled == 'function') {
			return this.disabled();
		}

		return this.disabled;
	}
}
