import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormArray, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { Customer } from '../../../classes/customer';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { DeviceService } from 'src/app/services/device.service';

@Component({
	templateUrl: './customer.html',
	styleUrls: ['./customer.scss'],
	selector: 'customer',
})
export class CustomerComponent extends FormComponent {
	public customerForm: UntypedFormGroup = null;

	_customer: Customer = null;
	get customer(): Customer {
		return this._customer;
	}

	@Input('customer')
	set customer(value: Customer) {
		if (value) {
			//      Create copy of the order and use it for editing
			this._customer = JSON.parse(JSON.stringify(value));

			//      Create our form group instance
			this.initializeCustomerForm();
		}
	}

	@Output('onRefresh')
	onRefresh = new EventEmitter<any>();

	constructor(public userService: UserService, private api: ApiService, private formBuilder: UntypedFormBuilder, public device: DeviceService) {
		super();
	}

	public initializeCustomerForm() {
		if (this.customer) {
			this.customerForm = this.formBuilder.group({
				id: new FormControl(this.customer.id),
				name: new FormControl(this.customer.name, [Validators.required]),
				phone: new FormControl(this.customer.phone),
				address: new FormControl(this.customer.address, [Validators.required]),
				street: new FormControl(this.customer.street, [Validators.required]),
				city: new FormControl(this.customer.city, Validators.required),
				state: new FormControl(this.customer.state, Validators.required),
				zipcode: new FormControl(this.customer.zipcode, Validators.required),
				email: new FormControl(this.customer.email, [Validators.required]),
				active: new FormControl(true),
				taxRate: new FormControl({ value: this.customer.taxRate || 0, disabled: !this.userService.isAdmin() }),
				taxExempt: new FormControl({ value: this.customer.taxExempt || false, disabled: !this.userService.isAdmin() }),
				laborRate: new FormControl({ value: this.customer.laborRate || 0, disabled: !this.userService.isAdmin() }),
				po_email: new FormControl(this.customer.po_email, [Validators.required]),
				labor_rates: new FormArray<FormGroup>(
					this.customer.labor_rates
						? this.customer.labor_rates.map((laborRate) => {
								return this.formBuilder.group({
									equipment_type: new FormControl<string>(laborRate.equipment_type, [Validators.required]),
									rate: new FormControl<number>(laborRate.rate, [Validators.required]),
								});
						  })
						: []
				),
			});
		}
	}

	/**
	 * Handles the submission of the customer form.
	 * Returns a promise that resolves after updating or creating a customer.
	 * Displays a success or error snackbar message accordingly.
	 */
	public onCustomerFormSubmit = () => {
		//	Promise to be returned
		let customerSubmitted = Promise.resolve();

		if (!this.customerForm || this.customerForm.invalid) {
			this.customerForm.markAllAsTouched();
			return customerSubmitted;
		}

		if (this.customerForm.value.id) {
			customerSubmitted = this.api.updateCustomer(this.customerForm.value).toPromise();
		} else {
			customerSubmitted = this.api.createCustomer(this.customerForm.value).toPromise();
		}

		return customerSubmitted
			.then(() => {
				SnackBarService.openSnackBarAlert('Customer saved.');
				this.onRefresh.emit();
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	};
}
