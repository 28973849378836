@if(!selectedUser) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>Users</h2>
		</mat-card-title>

		<mat-card-content>
			@if(!loadingService.loading) {
			<div style="max-width: calc(100vw - 158px); overflow: auto">
				<entity-selector-table [dataSource]="tableDataSource" [selectorTableColumns]="selectorTableColumns"> </entity-selector-table>
			</div>
			} @else {
			<div class="flex-container flex-align-items-center flex-justify-center p-3">
				<loading-spinner></loading-spinner>
			</div>
			}
		</mat-card-content>

		<mat-card-footer>
			<button (click)="newUserClicked()" mat-raised-button color="primary">Create Account</button>
		</mat-card-footer>
	</mat-card>
</div>
} @else {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card>
		<mat-card-title>
			<button mat-icon-button (click)="backClicked()">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>

		<mat-card-content>
			<user (onRefresh)="loadUsers($event)" [user]="selectedUser"></user>
		</mat-card-content>
	</mat-card>
</div>
}
