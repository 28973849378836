export function trimToNumber(number, precision) {
	if (number.toString().includes('.')) {
		var array = number.toString().split(".");
		array.push(array.pop().substring(0, precision));
		return Number(array.join(".")) as any;
	} else {
		return Number(number);
	}
}

export function trimToString(number, precision) {
	if (number.toString().includes('.')) {
		var array = number.toString().split(".");
		array.push(array.pop().substring(0, precision));
		return array.join(".");
	} else {
		return number + '.00';
	}
}

export function trim(number, precision, forcePrecision = true) {
	var array = number.toString().split(".");

	if (array.length > 1) {
		array.push(array.pop().substring(0, precision));
	}

	let value = array.join(".");

	//		Force the precision on the string
	if (forcePrecision) {

		if (value.split(".").length == 1 && precision > 0) {
			value += ".0";
		}

		while (value.split(".")[1].length < precision) {
			value += "0";
		}
	}

	return value;
}

export function padToLength(number: string | number, targetLength: number): string {
	if (number == null || number == undefined) {
		number = '0';
	}

	let currentLength = number.toString().length;

	while (currentLength < targetLength) {
		number = `0${number}`;

		currentLength = number.length;
	}

	return number.toString();
}