import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { SettingsService } from 'src/app/services/settings.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
	selector: 'settings',
	templateUrl: './settings.html',
	styleUrls: ['./settings.scss'],
})
export class SettingsComponent {
	public nextInvoiceNumber: number = null;

	public constructor(public userService: UserService, private apiService: ApiService, public settingsService: SettingsService) {}

	public changeInvoiceNumberClicked = () => {
		if (!this.nextInvoiceNumber) return Promise.resolve();

		return this.apiService
			.setNextInvoiceNumber(this.nextInvoiceNumber)
			.toPromise()
			.then((response) => {
				console.log(response);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	public toolbarVerticalChanged(event: MatSlideToggleChange) {
		let settings = JSON.parse(JSON.stringify(this.settingsService.uiSettings()));
		settings.toolbarVertical = event.checked;

		this.settingsService.uiSettings.set(settings);
	}
}
