export class User {
	public id: number;
	public username: string;
	public email: string;
	public mobile_phone: string;
	public firstname: string;
	public lastname: string;
	public user_type_id: number;
	public user_type: string;
	public vehicleId: number;
	public employee_number: number;
	public active: boolean;
	public isClockedIn: boolean;
	public google_id: string;

	constructor(data?: User) {
		if (data) {
			this.id = data.id;
			this.username = data.username;
			this.email = data.email;
			this.mobile_phone = data.mobile_phone;
			this.firstname = data.firstname;
			this.lastname = data.lastname;
			this.user_type_id = data.user_type_id;
			this.user_type = data.user_type;
			this.vehicleId = data.vehicleId;
			this.employee_number = data.employee_number;
			this.active = data.active;
			this.isClockedIn = data.isClockedIn;
			this.google_id = data.google_id;
		}
	}
}

export class UserType {
	public static Admin: string = "admin";
	public static User: string = "user";
	public static Manager: string = "manager";
}
