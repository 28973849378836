<div style="border-radius: 15">
	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input [formControl]="textFilterControl" matInput type="text" />
	</mat-form-field>

	<div [style.max-height]="tableMaxHeight" style="overflow: auto; border-radius: 0.5em">
		<table style="width: 100%" mat-table [dataSource]="dataSource">
			<div *ngFor="let column of selectorTableColumns" [ngSwitch]="column.type">
				<ng-container [matColumnDef]="column.columnProperty">
					<th [style.width]="column.columnWidth" mat-header-cell *matHeaderCellDef>{{ column.columnHeader }}</th>
					<td [style.width]="column.columnWidth" style="padding-right: 10px" mat-cell *matCellDef="let element; let i = index;">
						<div [style.width]="column.columnWidth" *ngSwitchCase="'data'">{{ column.getData(element) }}</div>

						<div [style.width]="column.columnWidth" *ngSwitchCase="'asyncData'">{{ column.getData(element) | async }}</div>

						<div [style.width]="column.columnWidth" *ngSwitchCase="'checkbox'">
							<mat-checkbox (change)="column.typeOptions.change($event, element)" [(ngModel)]="element[column.columnProperty]"></mat-checkbox>
						</div>

						<div *ngSwitchCase="'menu'">NOT YET IMPLEMENTED!</div>

						<div *ngSwitchCase="'custom'">
							<entity-selector-custom-component [element]="element" [column]="column"> </entity-selector-custom-component>
						</div>

						<div *ngSwitchCase="'button'" [style.width]="column.columnWidth">
							<button mat-mini-fab color="accent" [disabled]="column.disabled(element)" (click)="column.typeOptions.click(element)">
								<mat-icon>{{ column.typeOptions.icon }}</mat-icon>
							</button>
						</div>

						<div *ngSwitchCase="'asyncButton'" [style.width]="column.columnWidth">
							<loading-button
								[iconClass]="column.typeOptions.icon"
								[action]="column.typeOptions.click"
								[actionArgs]="[element, i]"
								[materialType]="column.typeOptions.materialType"
								[disabled]="column.typeOptions.disabled"></loading-button>
						</div>

						<mat-form-field [style.width]="column.columnWidth" *ngSwitchCase="'text'" [formGroup]="element">
							<mat-label>{{ column.inputLabel }}</mat-label>
							<input [attr.name]="column.columnProperty" matInput [formControlName]="column.columnProperty" type="text" />
							<mat-error *ngFor="let error of column.errors">
								<span *ngIf="formGroupHasError(element, column.columnProperty, error.name)" [innerHTML]="error.message"></span>
							</mat-error>
						</mat-form-field>

						<mat-form-field [style.width]="column.columnWidth" *ngSwitchCase="'number'" [formGroup]="element">
							<mat-label>{{ column.inputLabel }}</mat-label>
							<input [attr.name]="column.columnProperty" matInput [formControlName]="column.columnProperty" type="number" />
							<mat-error *ngFor="let error of column.errors">
								<span *ngIf="formGroupHasError(element, column.columnProperty, error.name)" [innerHTML]="error.message"></span>
							</mat-error>
						</mat-form-field>
					</td>
				</ng-container>
			</div>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>

	<mat-paginator style="border-radius: 0.5em" showFirstLastButtons="true" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="pageSize"> </mat-paginator>
</div>
