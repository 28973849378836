@if(vehicleForm) {
<div class="flex-container flex-container-column flex-gap flex-align-items-center flex-justify-center">
	<mat-card class="p-3">
		<mat-card-title>
			@if(!vehicle.id) {
			<h2>
				<span>New Vehicle</span>
			</h2>
			} @else {
			<h2>Vehicle</h2>
			}
		</mat-card-title>

		<mat-card-content>
			<form [formGroup]="vehicleForm">
				<div
					[class.flex-container-column]="device.isPhone"
					[class.flex-container-row]="!device.isPhone"
					class="flex-container flex-gap flex-align-items-center flex-justify-center p-3">
					<mat-form-field class="w-100">
						<mat-label>Unit #</mat-label>
						<input formControlName="unit_number" matInput type="text" />
						@if(formGroupHasError(vehicleForm, 'unit_number', 'required')) {
						<mat-error> Unit # is <strong>required</strong> </mat-error>
						}
					</mat-form-field>

					<mat-form-field class="w-100">
						<mat-label>VIN</mat-label>
						<input formControlName="vin" matInput type="text" />

						@if(formGroupHasError(vehicleForm, 'vin', 'required')) {
						<mat-error> Street is <strong>required</strong> </mat-error>
						}
					</mat-form-field>
				</div>

				<div
					[class.flex-container-column]="device.isPhone"
					[class.flex-container-row]="!device.isPhone"
					class="flex-container flex-gap flex-align-items-center flex-justify-center p-3">
					<mat-form-field class="w-100">
						<mat-label>License Number</mat-label>
						<input formControlName="license_number" matInput type="text" />

						@if(formGroupHasError(vehicleForm, 'license_number', 'required')) {
						<mat-error> License # is <strong>required</strong> </mat-error>
						}
					</mat-form-field>

					<mat-form-field class="w-100">
						<mat-label>Milage</mat-label>
						<input formControlName="milage" matInput type="number" />

						@if(formGroupHasError(vehicleForm, 'milage', 'required')) {
						<mat-error> Milage is <strong>required</strong> </mat-error>
						}
					</mat-form-field>
				</div>
			</form>

			<div
				[class.flex-container-column]="device.isPhone"
				[class.flex-container-row]="!device.isPhone"
				class="flex-container flex-gap flex-align-items-left p-3">
				<mat-form-field>
					<mat-label>Current Vehicle Inventory Amount</mat-label>
					<input readonly [(ngModel)]="totalProductsWorth" matInput type="text" />
				</mat-form-field>

				<loading-button [text]="'Inventory'" [action]="onShowInventoryUI"></loading-button>

				<loading-button [text]="'Add Product'" [action]="onAddProductClicked"></loading-button>

				<loading-button [text]="showingOnlyErroredRows ? 'Show All Rows' : 'Show Only Errored Rows'" [action]="showOnlyErroredRows"></loading-button>

				<loading-button [text]=" this.isInTransferMode ? 'Exit Transfer Mode' : 'Enter Transfer Mode'" [action]="toggleTransferMode"></loading-button>

				<div [formGroup]="vehicleForm">
					<mat-slide-toggle formControlName="is_fleet_vehicle">Is Fleet Vehicle</mat-slide-toggle>
				</div>
			</div>

			<!-- Products -->
			<div
				[class.flex-container-column]="!device.isHandset"
				[class.flex-container-row]="device.isHandset"
				class="flex-container flex-container-row flex-gap flex-align-items-stretch flex-justify-center p-3">
				<div style="max-width: calc(100vw - 158px); overflow: auto">
					<entity-selector-table
						[tableMaxHeight]="'32vh'"
						[dataSource]="tableDataSource"
						[selectorTableColumns]="selectorTableColumns"
						[filters]="entitySelectorTableFilters">
					</entity-selector-table>
				</div>
			</div>
			<br />

			<mat-divider></mat-divider>

			<br />

			@if(!this.isInTransferMode) {
			<div>
				<loading-button [text]="'Submit'" [action]="onVehicleFormSubmit"></loading-button>
			</div>
			} @else {
			<div class="flex-container flex-gap flex-align-items-center flex-justify-left">
				<vehicle-picker (vehicleIdChange)="vehicleIdChange($event)" [vehicleId]="this.selectedTransferVehicleId"></vehicle-picker>

				<loading-button [text]="'Transfer'" [action]="onTransferSelectedProducts"></loading-button>
			</div>
			}
		</mat-card-content>
	</mat-card>
</div>
}
