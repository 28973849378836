<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card class="p-3">
		<mat-card-title><h2>Purchase Order</h2></mat-card-title>

		<mat-card-content>
			@if(orderForm) {
			<form [formGroup]="orderForm">
				<div>
					<mat-form-field class="w-100 mr-3">
						<mat-label>Name</mat-label>
						<input readonly formControlName="name" matInput type="text" />
					</mat-form-field>

					<div class="w-100 mr-3">
						<store-picker
							[readonly]="true"
							[storeId]="orderForm.get('store_id').value"
							[validators]="[{key: 'required', message: 'Store is <strong>required</strong>'}]"
							[formGroupParent]="orderForm"
							[formGroupControlName]="'store_id'">
						</store-picker>
					</div>

					<mat-form-field class="w-100">
						<mat-label>Total Cost</mat-label>
						<input readonly formControlName="total_cost" matInput type="number" />
					</mat-form-field>
				</div>

				<div class="flex-container flex-gap flex-align-items-left">
					<div>
						<loading-button class="mr-3" [text]="'Add Product'" [action]="onAddProductClicked"></loading-button>
					</div>

					<div>
						<mat-slide-toggle formControlName="stocked">Stocked</mat-slide-toggle>
					</div>

					<div>
						<mat-slide-toggle formControlName="fuel">Fuel</mat-slide-toggle>
					</div>
				</div>

				<div style="max-width: calc(100vw - 158px); overflow: auto">
					<entity-selector-table [tableMaxHeight]="'45vh'" [dataSource]="tableDataSource" [selectorTableColumns]="selectorTableColumns">
					</entity-selector-table>
				</div>
			</form>
			}

			<mat-divider></mat-divider>

			<br />

			<div class="flex-container flex-container-row flex-gap mb-3">
				<loading-button class="mr-3" [text]="'Submit'" [action]="onPurchaseOrderFormSubmit"></loading-button>

				<loading-button [text]="'Download Receipt'" [action]="onDownloadReceipt"></loading-button>
				<loading-button [text]="'Add Core'" [action]="onAddCore"></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
