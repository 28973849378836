import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
	selector: 'loading-spinner',
	templateUrl: './loading_spinner.html',
	styleUrls: ['./loading_spinner.scss'],
})
export class LoadingSpinnerComponent {
	constructor(public loadingService: LoadingService) {}
}
