import { Component, Input, output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { UserService } from '../../../services/user.service';
import { Store } from '../../../classes/store';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';

@Component({
	selector: 'store',
	templateUrl: './store.html',
})
export class StoreComponent extends FormComponent {
	public storeForm: FormGroup = null;

	_store: Store = null;
	get store(): Store {
		return this._store;
	}

	@Input('store')
	set store(value: Store) {
		if (value) {
			//      Create copy of the order and use it for editing
			this._store = JSON.parse(JSON.stringify(value));

			//      Create our form group instance
			this.initializeStoreForm();
		}
	}

	onRefresh = output<any>();

	constructor(public userService: UserService, private api: ApiService, private formBuilder: FormBuilder) {
		super();
	}

	public initializeStoreForm() {
		if (this.store) {
			this.storeForm = this.formBuilder.group({
				id: new FormControl(this.store.id),
				name: new FormControl(this.store.name, [Validators.required]),
			});
		}
	}

	public onStoreFormSubmit = () => {
		//	Promise to be returned
		let customerSubmitted = Promise.resolve();

		if (!this.storeForm || this.storeForm.invalid) {
			this.storeForm.markAllAsTouched();
			return customerSubmitted;
		}

		if (this.storeForm.value.id) {
			customerSubmitted = this.api.updateStore(this.storeForm.value).toPromise();
		} else {
			customerSubmitted = this.api.createStore(this.storeForm.value).toPromise();
		}

		return customerSubmitted
			.then(() => {
				SnackBarService.openSnackBarAlert('Store saved.');
				this.onRefresh.emit(null);
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	};
}
