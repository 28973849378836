@if(quoteForm) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card class="p-3">
		<mat-card-title>
			@if(!quote.id) {
			<h5>
				<span>New Quote</span>
			</h5>
			} @else if(quote.id) {
			<h5>Quote - {{ padQuoteNumber(quote.id, 5) }}</h5>
			}
		</mat-card-title>

		<mat-card-content>
			<form [formGroup]="quoteForm">
				<mat-tab-group>
					<mat-tab label="Basic Information">
						<div class="flex-container flex-container-column flex-gap flex-align-items-center flex-justify-center">
							<div class="flex-container flex-container-column flex-align-items-center flex-justify-center mt-3 w-100">
								<customer-picker
									class="w-100"
									[customerSelectedHandler]="customerSelectedHandler"
									[customerId]="quoteForm.get('customer_id').value"
									[validators]="[{key: 'required', message: 'Customer is <strong>required</strong>'}]"
									[formGroupParent]="quoteForm"
									[formGroupControlName]="'customer_id'">
								</customer-picker>

								<vehicle-picker
									class="w-100"
									[formGroupParent]="quoteForm.get('vehicle')"
									[label]="'Originating Vehicle'"
									[vehicleId]="quoteForm.get('vehicle.id')?.value"
									[formGroupControlName]="'id'"
									[validators]="[{key: 'required', message: 'Vehicle is <strong>required</strong>'}]"
									[optionSelectedHandler]="vehicleSelectedHandler">
								</vehicle-picker>
							</div>

							<div class="w-100" [formGroup]="quoteForm.get('vehicle')">
								<mat-form-field class="w-100">
									<mat-label>Vehicle Type</mat-label>
									<mat-select (selectionChange)="vehicleTypeChanged($event)" formControlName="equipment_type">
										<mat-option></mat-option>
										<mat-option [value]="'truck'">Truck</mat-option>
										<mat-option [value]="'trailer'">Trailer</mat-option>
										<mat-option [value]="'equipment'">Equipment</mat-option>
									</mat-select>

									@if(formGroupHasError(quoteForm.get('vehicle'), 'equipment_type', 'required')) {
									<mat-error>Vehicle type is <strong>required</strong></mat-error>
									}
								</mat-form-field>
							</div>

							<div class="flex-container flex-container-row flex-gap w-100" [formGroup]="quoteForm.get('vehicle')">
								<mat-form-field class="w-100">
									<mat-label>Unit Number</mat-label>
									<input formControlName="unit_number" matInput type="text" />

									@if(formGroupHasError(quoteForm.get('vehicle'), 'unit_number', 'required')) {
									<mat-error> Unit number is <strong>required</strong> </mat-error>
									}
								</mat-form-field>

								<mat-form-field class="w-100">
									<mat-label>VIN</mat-label>
									<input formControlName="vin" matInput type="text" />

									@if(formGroupHasError(quoteForm.get('vehicle'), 'vin', 'required')) {
									<mat-error> VIN is <strong>required</strong> </mat-error>
									}
								</mat-form-field>
							</div>

							<div class="flex-container flex-container-row flex-gap w-100" [formGroup]="quoteForm.get('vehicle')">
								<mat-form-field class="w-100">
									<mat-label>Milage</mat-label>
									<input formControlName="milage" matInput type="number" />

									@if(formGroupHasError(quoteForm.get('vehicle'), 'milage', 'required')) {
									<mat-error> Milage is <strong>required</strong> </mat-error>
									}
								</mat-form-field>

								<mat-form-field class="w-100">
									<mat-label>License Number</mat-label>
									<input formControlName="license_number" matInput type="text" />

									@if(formGroupHasError(quoteForm.get('vehicle'), 'license_number', 'required')) {
									<mat-error> License number is <strong>required</strong> </mat-error>
									}
								</mat-form-field>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Line Items">
						<div class="mt-3" style="max-height: 65vh; overflow: auto">
							<div
								[class.flex-container-column]="device.isPhone"
								[class.flex-container-row]="!device.isPhone"
								class="flex-container flex-gap flex-align-items-center flex-justify-left">
								<span class="mr-3"><strong>Item: </strong>{{ selectedLineItemIndex + 1 }} of {{ quoteForm.get('lineItems').length }}</span>

								<button (click)="previousLineItem()" class="mr-3" mat-stroked-button color="warn">Previous Item</button>
								<button (click)="nextLineItem()" class="mr-3" mat-stroked-button color="primary">Next Item</button>

								<button (click)="addLineItem()" class="mr-3" mat-stroked-button color="basic">Add New Item</button>
								<button (click)="removeLineItem()" class="mr-3" mat-stroked-button color="basic">Delete Current Item</button>
							</div>

							@if(selectedLineItem) {
							<div class="mt-3" *ngIf="selectedLineItem" [formGroup]="selectedLineItem">
								<div
									[class.flex-container-column]="device.isPhone"
									[class.flex-container-row]="!device.isPhone"
									class="flex-container flex-gap flex-align-items-center flex-justify-center">
									<mat-form-field class="w-50">
										<mat-label>Complaint</mat-label>
										<textarea rows="3" formControlName="complaint" matInput type="text"></textarea>

										@if(formGroupHasError(selectedLineItem, 'complaint', 'required')) {
										<mat-error>
											Customer complaint is
											<strong>required</strong> </mat-error
										>}
									</mat-form-field>

									<mat-form-field class="w-50">
										<mat-label>Cause</mat-label>
										<textarea rows="3" formControlName="cause" matInput type="text"></textarea>

										@if(formGroupHasError(selectedLineItem, 'cause', 'required')) {
										<mat-error> Cause is <strong>required</strong> </mat-error>}
									</mat-form-field>

									<mat-form-field class="w-50">
										<mat-label>Correction</mat-label>
										<textarea rows="3" formControlName="correction" matInput type="text"></textarea>

										@if(formGroupHasError(selectedLineItem, 'correction', 'required')) {
										<mat-error>
											Correction is
											<strong>required</strong> </mat-error
										>}
									</mat-form-field>
								</div>

								<div
									[class.flex-container-column]="device.isPhone"
									[class.flex-container-row]="!device.isPhone"
									class="flex-container flex-gap flex-align-items-center flex-justify-center">
									<div class="w-100">
										<button (click)="addProduct()" mat-stroked-button color="basic">+ New Product</button>
									</div>

									<div class="w-100">
										<button (click)="openVehicleProductsSelector()" mat-stroked-button color="basic">+ Inventory Product</button>
									</div>

									<mat-form-field class="w-100">
										<mat-label>Hours</mat-label>
										<input formControlName="hours" matInput type="number" />

										@if(formGroupHasError(selectedLineItem, 'hours', 'required')) {
										<mat-error> Hours are <strong>required</strong> </mat-error>
										}
									</mat-form-field>

									@if(userService.isAdmin() || userService.isManager()) {
									<mat-form-field class="w-100">
										<mat-label>Rate</mat-label>
										<input formControlName="rate" matInput type="number" />

										@if(formGroupHasError(selectedLineItem, 'rate', 'required')) {
										<mat-error> Rate is <strong>required</strong> </mat-error>
										}
									</mat-form-field>
									}
								</div>

								<div style="max-width: calc(100vw - 158px); overflow: auto">
									<entity-selector-table
										[tableMaxHeight]="'35vh'"
										[dataSource]="tableDataSource"
										[selectorTableColumns]="selectorTableColumns"
										[pageSize]="50">
									</entity-selector-table>
								</div>
							</div>
							}
						</div>
					</mat-tab>

					@if(userService.isAdmin() || userService.isManager()) {
					<mat-tab label="Invoice">
						<div
							[class.flex-container-column]="device.isPhone"
							[class.flex-container-row]="!device.isPhone"
							class="flex-container flex-gap flex-align-items-center flex-justify-center">
							<mat-form-field class="w-100">
								<mat-label>Tax Rate</mat-label>
								<input [(ngModel)]="taxRate" matInput type="number" [ngModelOptions]="{standalone: true}" />
							</mat-form-field>
							<div class="mb-3 w-100">
								<mat-slide-toggle [(ngModel)]="taxExempt" [ngModelOptions]="{standalone: true}">Tax Exempt </mat-slide-toggle>
							</div>
						</div>

						<div
							[class.flex-container-column]="device.isPhone"
							[class.flex-container-row]="!device.isPhone"
							class="flex-container flex-gap flex-align-items-center flex-justify-center">
							<mat-form-field class="w-100">
								<mat-label>Other Fee's</mat-label>
								<input [(ngModel)]="otherFees" matInput type="number" [ngModelOptions]="{standalone: true}" />
							</mat-form-field>
							<mat-form-field class="w-100">
								<mat-label>Disposal Fee</mat-label>
								<input [(ngModel)]="disposalFees" matInput type="number" [ngModelOptions]="{standalone: true}" />
							</mat-form-field>
						</div>

						<div>
							<loading-button [text]="'Download Invoice'" [action]="downloadInvoiceClicked"></loading-button>
						</div>
					</mat-tab>
					}
				</mat-tab-group>
			</form>
			<br />

			<mat-divider></mat-divider>

			<br />

			<div
				[class.flex-container-column]="device.isPhone"
				[class.flex-container-row]="!device.isPhone"
				class="flex-container flex-gap flex-align-items-center flex-justify-left">
				<loading-button [text]="'Submit Quote'" [action]="onQuoteSubmit" [disabled]="userCantEdit()"></loading-button>

				@if(this.quote.id) {
				<loading-button [text]="'Delete Quote'" [action]="deleteQuoteClicked" [disabled]="userCantEdit()"></loading-button>
				<loading-button
					*ngIf="this.quote.id"
					[text]="'Convert to Repair Order'"
					[action]="convertToRepairOrder"
					[disabled]="loadingService.loading"></loading-button>
				}
			</div>
		</mat-card-content>
	</mat-card>
</div>

}
