import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'userFriendlyHours'
})
export class UserFriendlyHours implements PipeTransform {
	public transform(value:number) : string {
		let hours = 0, minutes = 0;

		if(value && !Number.isNaN(value)) {
			let parts = value.toString().split('.');
		
			if(parts && parts.length) {
					hours = parts[0] as any;
				if(parts.length > 1) {
						minutes = new Number('.' + parts[1]) as any;

					if(!Number.isNaN(minutes) && minutes > 0) {
						minutes = ((minutes / 100) * 60 * 100).toFixed(0) as any;
					}
				}
			}
		}

		return `${hours}h ${minutes}m`;
	}
}