@if(customerForm) { }

<div class="flex-container flex-container-column flex-gap flex-align-items-center flex-justify-center">
	<mat-card class="p-3">
		<mat-card-title>
			@if(!customer.id) {
			<h2>
				<span>New Customer</span>
			</h2>
			} @else {
			<h2>Customer</h2>
			}
		</mat-card-title>

		<mat-card-content>
			<form [formGroup]="customerForm">
				<div>
					<mat-form-field class="w-100">
						<mat-label>Name</mat-label>
						<input formControlName="name" matInput type="text" />
						<mat-error *ngIf="formGroupHasError(customerForm, 'name', 'required')"> Name is <strong>required</strong> </mat-error>
					</mat-form-field>
				</div>

				<div>
					<mat-form-field class="w-100">
						<mat-label>Phone</mat-label>
						<input formControlName="phone" matInput type="text" />
					</mat-form-field>
				</div>

				<div
					[class.flex-container-column]="device.isPhone"
					[class.flex-container-row]="!device.isPhone"
					class="flex-container flex-gap flex-align-items-center flex-justify-center">
					<mat-form-field class="w-100">
						<mat-label>Address</mat-label>
						<input formControlName="address" matInput type="text" />
						<mat-error *ngIf="formGroupHasError(customerForm, 'address', 'required')"> Address is <strong>required</strong> </mat-error>
					</mat-form-field>

					<mat-form-field class="w-100">
						<mat-label>Street</mat-label>
						<input formControlName="street" matInput type="text" />
						<mat-error *ngIf="formGroupHasError(customerForm, 'street', 'required')"> Street is <strong>required</strong> </mat-error>
					</mat-form-field>

					<mat-form-field class="w-100">
						<mat-label>City</mat-label>
						<input formControlName="city" matInput type="text" />
						<mat-error *ngIf="formGroupHasError(customerForm, 'city', 'required')"> City is <strong>required</strong> </mat-error>
					</mat-form-field>
				</div>

				<div
					[class.flex-container-column]="device.isPhone"
					[class.flex-container-row]="!device.isPhone"
					class="flex-container flex-gap flex-align-items-center flex-justify-center">
					<mat-form-field class="w-100">
						<mat-label>State</mat-label>
						<input formControlName="state" matInput type="text" />
						<mat-error *ngIf="formGroupHasError(customerForm, 'state', 'required')"> State is <strong>required</strong> </mat-error>
					</mat-form-field>

					<mat-form-field class="w-100">
						<mat-label>Zip</mat-label>
						<input formControlName="zipcode" matInput type="text" />
						<mat-error *ngIf="formGroupHasError(customerForm, 'zip', 'required')"> Zip is <strong>required</strong> </mat-error>
					</mat-form-field>
				</div>

				<div
					[class.flex-container-column]="device.isPhone"
					[class.flex-container-row]="!device.isPhone"
					class="flex-container flex-gap flex-align-items-center flex-justify-center">
					<mat-form-field class="w-100">
						<mat-label>Accouting Email</mat-label>
						<input formControlName="email" matInput type="text" />
						<mat-error *ngIf="formGroupHasError(customerForm, 'email', 'required')"> Email is <strong>required</strong> </mat-error>
						<mat-error *ngIf="formGroupHasError(customerForm, 'email', 'email')"> Not a valid email address </mat-error>
					</mat-form-field>

					<mat-form-field class="w-100">
						<mat-label>PO Email</mat-label>
						<input formControlName="po_email" matInput type="text" />
						<mat-error *ngIf="formGroupHasError(customerForm, 'po_email', 'required')"> PO email is <strong>required</strong> </mat-error>
						<mat-error *ngIf="formGroupHasError(customerForm, 'po_email', 'email')"> Not a valid email address </mat-error>
					</mat-form-field>
				</div>

				<div>
					<mat-form-field class="w-100">
						<mat-label>Tax Rate</mat-label>
						<input formControlName="taxRate" matInput type="number" />
					</mat-form-field>
				</div>

				<div>
					<mat-form-field class="w-100">
						<mat-label>Default Labor Rate</mat-label>
						<input formControlName="laborRate" matInput type="number" />
					</mat-form-field>

					<customer-labor-rate
						class="w-100"
						*ngIf="customerForm && customerForm.get('labor_rates').value"
						[laborRates]="customerForm.get('labor_rates')"></customer-labor-rate>
				</div>

				<div>
					<mat-checkbox class="mr-3" formControlName="taxExempt">Tax Exempt</mat-checkbox>
					<mat-checkbox formControlName="active">Active</mat-checkbox>
				</div>
			</form>
			<br />

			<mat-divider></mat-divider>

			<br />

			<div>
				<loading-button [text]="'Submit'" [action]="onCustomerFormSubmit"></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
