import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, catchError } from 'rxjs/operators';
import { User } from 'src/app/classes/user';
import { ApiService } from 'src/app/services/api.service';
import { ReactiveFormWrapper } from 'src/app/services/reactiveFormsWrapper';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'user-picker',
	templateUrl: './userPicker.html',
	styleUrls: ['./userPicker.scss'],
})
export class UserPicker extends ReactiveFormWrapper {
	@Input() userId: number = null;

	public users: User[];
	public filteredUsers: Observable<User[]> = of([]);
	constructor(private api: ApiService, public userService: UserService) {
		super();
		this.controlSet.subscribe(() => {
			this.init();
		});
		this.init();
	}

	public init() {
		this.api
			.getAllUsers()
			.pipe(catchError((error) => of([])))
			.toPromise()
			.then((users) => {
				if (!this.userService.isAdmin()) {
					users = users.filter((user) => user.id == this.userService.user.id);
				}

				this.users = users;

				if (this.users && this.userId) {
					this.control.setValue(this.users.find((user) => user.id == this.userId)?.id);
				}

				this.filteredUsers = this.control.valueChanges.pipe(
					startWith(''),
					debounceTime(200),
					distinctUntilChanged(),
					map((userText) => this.filterUsers(userText)),
					catchError((error) => {
						console.error(error);
						return of([]);
					})
				);
			});
	}

	public filterUsers(value: string): User[] {
		if (!this.users) return [];

		return this.users.filter((user) => {
			if (value == null || value == undefined || value == '') return true;

			value = value.toString().toLowerCase();

			return (
				(user.firstname || '').toLowerCase().includes(value) ||
				(user.lastname || '').toLowerCase().includes(value) ||
				(user.username || '').toLowerCase().includes(value)
			);
		});
	}

	public displayFn = (id) => {
		if (!this.users) return '';

		let theUser = this.users.find((user) => user.id == id);
		let display = null;

		if (theUser && id >= 0) {
			display = (theUser.firstname || '') + ' ' + (theUser.lastname || '') + ' - ' + (theUser.employee_number || '');
		}

		return display;
	};
}
