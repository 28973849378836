@if(!selectedOrder) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>Repair Orders</h2>
		</mat-card-title>

		<mat-card-content class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
			<div>
				<mat-form-field>
					<mat-label>Search All Orders</mat-label>
					<input [formControl]="repairOrderSearchInput" matInput [matAutocomplete]="auto" />
					<mat-autocomplete (optionSelected)="onSearchRepairOrdersChange($event)" #auto="matAutocomplete">
						@for (repairOrder of searchedRepairOrders | async; track repairOrder) {
						<mat-option [value]="repairOrder">
							<span>{{ repairOrder.vehicle.unit_number }} - {{ repairOrder.customer.name }}</span>
						</mat-option>
						}
					</mat-autocomplete>
				</mat-form-field>
			</div>

			<div class="mb-3">
				<loading-button [text]="'Load 200 more'" [action]="loadMoreOrders"></loading-button>
			</div>

			<div
				[class.flex-container-column]="device.isPhone"
				[class.flex-container-row]="!device.isPhone"
				class="flex-container flex-gap flex-align-items-center flex-justify-left">
				<mat-button-toggle-group #group (change)="statusFilterUpdated($event)" [(ngModel)]="statusFilter" name="filterGroup">
					<mat-button-toggle [value]="1">All</mat-button-toggle>
					<mat-button-toggle [value]="2">Open</mat-button-toggle>
					<mat-button-toggle [value]="4">Complete</mat-button-toggle>
					<mat-button-toggle [value]="3">Closed</mat-button-toggle>
				</mat-button-toggle-group>

				<loading-button [text]="'Load 200 more'" [action]="loadMoreOrders"></loading-button>

				@if(userService.isAdmin()){
				<loading-button [text]="mode != 'merge' ? 'Merge Mode' : 'Normal Mode'" [action]="toggleMode"></loading-button>
				}
			</div>

			@if(!initializing) {
			<div class="mt-3" style="max-width: calc(100vw - 158px); overflow: auto">
				<entity-selector-table
					[tableMaxHeight]="'50vh'"
					[dataSource]="tableDataSource"
					[textFilterControl]="entityTableFilter"
					[selectorTableColumns]="selectorTableColumns"></entity-selector-table>
			</div>
			} @else {
			<div class="flex-container flex-align-items-center flex-justify-center p-3">
				<loading-spinner></loading-spinner>
			</div>
			}
		</mat-card-content>

		<mat-card-footer>
			<button (click)="newOrderClicked()" mat-raised-button color="primary">New Order</button>
		</mat-card-footer>
	</mat-card>
</div>
} @if(selectedOrder) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card>
		<mat-card-title>
			<button mat-icon-button (click)="backButtonClicked()">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>

		<mat-card-content>
			<repair-order (refreshRepairOrders)="onRefresh($event)" [order]="selectedOrder"></repair-order>
		</mat-card-content>
	</mat-card>
</div>
}
