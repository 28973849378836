import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { Customer } from '../../classes/customer';
import { MatTableDataSource } from '@angular/material/table';
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../entity_selector_table/entitySelectorTable';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
	templateUrl: './customers.html',
	styleUrls: ['./customers.scss'],
	selector: 'customers',
})
export class CustomersComponent {
	public selectedCustomer: Customer = null;
	public tableDataSource = new MatTableDataSource();

	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	constructor(public userService: UserService, private api: ApiService, public loadingService: LoadingService) {
		this.loadCustomers();
		this.setupEntitySelectorTableDataSource();
	}

	/**
	 * Set up the columns for the selector table including an edit button and a name column.
	 * The edit button allows selecting a customer, and the name column filters customers by name.
	 */
	public setupEntitySelectorTableDataSource() {
		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = '';
		editButton.columnProperty = 'edit';
		editButton.columnWidth = '0px';
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: 'edit',
			click: (customer: Customer) => {
				this.selectedCustomer = customer;
			},
		};
		this.selectorTableColumns.push(editButton);

		let nameColumn = new EntitySelectorTableColumn();
		nameColumn.columnHeader = 'Name';
		nameColumn.columnProperty = 'name';
		nameColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(nameColumn);
		this.tableDataSource.filterPredicate = (customer: Customer, filterText: string) => {
			let name = customer.name;
			if (name == null || name == undefined) name = '';

			return name.toLowerCase().indexOf(filterText.trim()) > -1;
		};
	}

	public backClicked() {
		this.selectedCustomer = null;
		//		Force filtering to reoccur
		this.tableDataSource.filter = this.tableDataSource.filter + ' ';
	}

	public loadCustomers() {
		return this.api
			.getAllCustomers()
			.toPromise()
			.then((results) => {
				let customers = results;

				if (customers && customers.length) {
					customers = customers.sort((a, b) => {
						let aCustomer = (a.name && a.name.toLowerCase()) || '';
						let bCustomer = (b.name && b.name.toLowerCase()) || '';

						if (aCustomer > bCustomer) return 1;
						if (aCustomer < bCustomer) return -1;
						return 0;
					});
				}

				this.tableDataSource.data = customers;

				if (this.selectedCustomer && this.selectedCustomer.id) {
					this.selectedCustomer = customers.find((customer) => customer.id == this.selectedCustomer.id);
				} else {
					this.selectedCustomer = null;
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	public newCustomerClicked() {
		this.selectedCustomer = new Customer();
		this.selectedCustomer.labor_rates = [
			{ equipment_type: 'truck', rate: null },
			{ equipment_type: 'trailer', rate: null },
			{ equipment_type: 'equipment', rate: null },
		];
	}
}
