@if(!selectedVehicle) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>Vehicles</h2>
		</mat-card-title>

		<mat-card-content>
			@if(loadingService.loading) {
			<div class="flex-container flex-align-items-center flex-justify-center">
				<loading-spinner></loading-spinner>
			</div>
			} @else {
			<div style="max-width: calc(100vw - 158px); overflow: auto">
				<entity-selector-table
					[tableMaxHeight]="'50vh'"
					[dataSource]="tableDataSource"
					[selectorTableColumns]="selectorTableColumns"></entity-selector-table>
			</div>
			}
		</mat-card-content>

		<mat-card-footer>
			<button (click)="newVehicleClicked()" mat-raised-button color="primary">Create Vehicle</button>

			@if(this.userService.isAdmin()) {
			<mat-slide-toggle class="ml-3" (change)="onlyUserVehiclesChanged()" [(ngModel)]="showOnlyUserVehicles"> Only User Vehicles </mat-slide-toggle>
			<mat-slide-toggle class="ml-3" (change)="onlyFleetVehiclesChanged()" [(ngModel)]="showOnlyFleetVehicles"> Only Fleet Vehicles </mat-slide-toggle>
			}
		</mat-card-footer>
	</mat-card>
</div>
} @if(selectedVehicle) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3 p-3">
	<mat-card>
		<mat-card-title>
			<button mat-icon-button (click)="backClicked()">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>

		<mat-card-content>
			<vehicle (onRefresh)="loadVehicles($event)" [vehicle]="selectedVehicle"> </vehicle>
		</mat-card-content>
	</mat-card>
</div>
}
