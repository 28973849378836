import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { Vehicle } from '../../../classes/vehicle';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { VehicleInventory } from '../vehicle_inventory/vehicleInventory.component';
import { MatTableDataSource } from '@angular/material/table';
import { EntitySelectorTable, EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../../entity_selector_table/entitySelectorTable';
import Decimal from 'decimal.js';
import { trimToNumber } from 'src/app/services/Helper';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { Product } from 'src/app/classes/quote';
import { DeviceService } from 'src/app/services/device.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
	templateUrl: './vehicle.html',
	styleUrls: ['./vehicle.scss'],
	selector: 'vehicle',
})
export class VehicleComponent extends FormComponent implements OnInit, AfterViewInit {
	public vehicleForm: FormGroup = null;
	public vehicleProductsForm: FormArray = null;

	_vehicle: Vehicle = null;
	get vehicle(): Vehicle {
		return this._vehicle;
	}

	@Input('vehicle')
	set vehicle(value: Vehicle) {
		if (value) {
			//      Create copy of the order and use it for editing
			this._vehicle = JSON.parse(JSON.stringify(value));

			//      Create our form group instance
			this.initializeVehicleForm();
		}
	}

	@Output('onRefresh')
	onRefresh = new EventEmitter<any>();

	public totalProductsWorth: string = '0.00';

	public tableDataSource = new MatTableDataSource();
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	public selectedIdsForTransfer: Set<number> = new Set<number>();
	public isInTransferMode: boolean = false;

	@ViewChild(EntitySelectorTable) entityTable: EntitySelectorTable;
	public entitySelectorTableFilters: any[] = [
		(dataSource: MatTableDataSource<any>) => {
			dataSource.data = dataSource.data.filter((productForm: FormGroup) => {
				//		Touch each control
				Object.keys(productForm.controls).forEach((key) => {
					productForm.get(key).markAsTouched();
				});
				return !productForm.valid;
			});
		},
	];

	constructor(
		public userService: UserService,
		private api: ApiService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		public device: DeviceService
	) {
		super();
	}

	public ngOnInit() {
		//		Set up the columns for the selector table
		let deleteButton = new EntitySelectorTableColumn();
		deleteButton.columnHeader = '';
		deleteButton.columnProperty = 'delete';
		deleteButton.columnWidth = '50px';
		deleteButton.type = EntitySelectorTableColumnType.asyncButton;
		deleteButton.typeOptions = {
			icon: 'delete',
			click: (productForm: FormGroup, index: number) => {
				const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
				return dialogRef
					.afterClosed()
					.toPromise()
					.then((result) => {
						if (result) {
							if (!productForm || !productForm.value || !productForm.value.id) {
								if (index > -1) {
									let data = this.tableDataSource.data;
									data.splice(index, 1);
									this.tableDataSource.data = data;
								}

								return Promise.resolve();
							}

							return this.deleteProducts([productForm]);
						}
					})
					.catch((error) => {
						console.error(error);
					});
			},
			materialType: 'mat-mini-fab',
		};
		this.selectorTableColumns.push(deleteButton);

		let costColumn = new EntitySelectorTableColumn();
		costColumn.columnHeader = 'Cost';
		costColumn.inputLabel = 'Cost';
		costColumn.columnProperty = 'cost';
		costColumn.type = EntitySelectorTableColumnType.number;
		costColumn.errors = [
			{
				message: 'Cost is required!',
				name: Validators.required.name,
			},
			{
				message: 'Cost must be greater than or equal to 0',
				name: Validators.min.name,
			},
		];

		this.selectorTableColumns.push(costColumn);

		let quantityColumn = new EntitySelectorTableColumn();
		quantityColumn.columnHeader = 'Quantity';
		quantityColumn.inputLabel = 'Quantity';
		quantityColumn.columnProperty = 'quantity';
		quantityColumn.type = EntitySelectorTableColumnType.number;
		quantityColumn.errors = [
			{
				message: 'Quantity is required!',
				name: Validators.required.name,
			},
			{
				message: 'Quantity must be greater than or equal to 0',
				name: Validators.min.name,
			},
		];

		this.selectorTableColumns.push(quantityColumn);

		let descriptionColumn = new EntitySelectorTableColumn();
		descriptionColumn.columnHeader = 'Description';
		descriptionColumn.inputLabel = 'Description';
		descriptionColumn.columnProperty = 'description';
		descriptionColumn.type = EntitySelectorTableColumnType.text;
		descriptionColumn.errors = [
			{
				message: 'Description is required!',
				name: Validators.required.name,
			},
		];

		this.selectorTableColumns.push(descriptionColumn);

		let partNumberColumn = new EntitySelectorTableColumn();
		partNumberColumn.columnHeader = 'Part Number';
		partNumberColumn.inputLabel = 'Part Number';
		partNumberColumn.columnProperty = 'part_number';
		partNumberColumn.type = EntitySelectorTableColumnType.text;
		partNumberColumn.errors = [
			{
				message: 'Part number is required!',
				name: Validators.required.name,
			},
			{
				message: 'Duplicate part number identified.',
				name: 'duplicatePartNumber',
			},
		];

		this.selectorTableColumns.push(partNumberColumn);

		this.tableDataSource.filterPredicate = (productForm: FormGroup, filterText: string) => {
			let product: Product = productForm.value || {};

			let name = product.part_number + ' ' + product.description;
			if (name == null || name == undefined) name = '';

			name = name.toLowerCase().trim();

			let results = name.includes(filterText.trim());

			return results;
		};
	}

	public ngAfterViewInit() {
		this.getVehicleProducts();
	}

	public showingOnlyErroredRows = false;
	public showOnlyErroredRows = () => {
		this.showingOnlyErroredRows = !this.showingOnlyErroredRows;

		if (this.entityTable) {
			if (this.showingOnlyErroredRows) {
				this.entityTable.applyFilters();
			} else {
				this.entityTable.unapplyFilters();
			}
		}

		return Promise.resolve();
	};

	public calculateTotalProductWorth() {
		this.totalProductsWorth = '0.00';

		//		Calculate the amount of money on the vehicle
		let calculatedAmount: number = 0;
		(this.vehicleProductsForm.value as Product[]).map((form) => {
			let vehicleProduct: Product = form || ({} as Product);

			let cost = new Decimal(trimToNumber(vehicleProduct.cost || 0, 2));
			let quantity = new Decimal(trimToNumber(vehicleProduct.quantity || 0, 2));

			calculatedAmount += cost.times(quantity).toNumber();
		});

		this.totalProductsWorth = '$' + trimToNumber(calculatedAmount, 2).toString();
	}

	public deleteProducts(productForms: FormGroup[]): Promise<any> {
		if (!productForms || productForms.length <= 0 || !this.vehicleForm.get('id') || !(this.vehicleForm.get('id').value >= 0)) {
			return Promise.resolve();
		}

		productForms = productForms.filter((productForm) => productForm && productForm.value && productForm.value.id >= 0);

		return this.api
			.deleteVehicleProducts({ vehicleId: this.vehicleForm.get('id').value as number, products: productForms.map((form) => form.value) })
			.toPromise()
			.then(() => {
				SnackBarService.openSnackBarAlert('Vehicle product deleted.');
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			})
			.finally(() => {
				return this.getVehicleProducts();
			});
	}

	public saveProducts(productForms: FormGroup[]): Promise<any> {
		if (!productForms || productForms.length <= 0 || !this.vehicleForm.get('id') || !((this.vehicleForm.get('id').value as number) >= 0)) {
			return Promise.resolve();
		}

		return this.api
			.getAllProducts()
			.toPromise()
			.then((allProducts) => {
				if (productForms.find((form) => form.invalid)) {
					productForms.forEach((form: FormGroup) => {
						Object.keys(form.controls).forEach((key) => {
							form.controls[key].markAsDirty();
							form.controls[key].markAsTouched();
						});
					});
					return Promise.reject({ error: { message: 'Cannot save new or existing product edits. Missing or invalid information found.' } });
				}

				//		Filter out invalid forms for the request
				productForms = productForms.filter((productForm) => productForm && productForm.valid);

				let newProducts: FormGroup[] = productForms.filter((productForm) => productForm.value && !productForm.value.id);

				newProducts.map((productForm: FormGroup) => {
					let product: Product = productForm.value || {};
					let partNumber = (product.part_number || '').toLowerCase().trim();

					let foundProduct = allProducts.find((theProduct) => {
						let theProductPartNumber = (theProduct.part_number || '').toLowerCase().trim();

						return theProductPartNumber == partNumber;
					});

					if (foundProduct) {
						productForm.get('id').patchValue(foundProduct.id);
					}
				});

				let existingProducts: FormGroup[] = productForms
					.filter((productForm) => productForm.value && productForm.value.id)
					.map((productForm) => {
						let existingProduct: Product = productForm.value;
						let existingPartNumber = ((existingProduct && existingProduct.part_number) || '').toLowerCase().trim();

						let newProductInfoIndex = newProducts.findIndex((newProductForm) => {
							let newProduct: Product = newProductForm.value;
							let newPartNumber = ((newProduct && newProduct.part_number) || '').toLowerCase().trim();

							return existingPartNumber == newPartNumber;
						});

						if (newProductInfoIndex >= 0) {
							let newProduct: Product = newProducts[newProductInfoIndex].value;
							existingProduct.cost = newProduct.cost;
							existingProduct.quantity = newProduct.quantity;

							newProducts.splice(newProductInfoIndex, 1);
						}

						return productForm;
					});

				let newProductsCreated = Promise.all(
					newProducts.map((newProduct) => {
						return this.api.createProduct(newProduct.value).toPromise();
					})
				).then((createdProducts) => {
					createdProducts = createdProducts.map((createdProduct: Product, index) => {
						let newProductForm = newProducts[index];
						let newProduct: Product = newProductForm.value || null;

						createdProduct.quantity = newProduct && newProduct.quantity;
						createdProduct.cost = newProduct && newProduct.cost;

						return createdProduct;
					});

					return this.api.createVehicleProducts({ vehicleId: this.vehicleForm.get('id').value, products: createdProducts }).toPromise();
				});

				let productsSaved = Promise.all([
					this.api
						.updateVehicleProducts(
							this.vehicleForm.get('id').value as number,
							existingProducts.map((productForm) => productForm.value)
						)
						.toPromise(),
					newProductsCreated,
				])
					.then(() => {
						SnackBarService.openSnackBarAlert('Products saved.');
					})
					.catch((error) => {
						SnackBarService.openSnackBarAlert(error.error.message, 'red');
						console.error(error);
					});

				return productsSaved.finally(() => {
					return this.getVehicleProducts();
				});
			});
	}

	public onAddProductClicked = () => {
		let productAdded = Promise.resolve();

		let tableData = this.tableDataSource.data;
		let productForm = this.getProductForm();
		productForm.markAsDirty();
		productForm.markAsTouched();

		tableData.unshift(productForm);
		this.tableDataSource.data = tableData;

		this.tableDataSource.paginator.firstPage();

		return productAdded;
	};

	public initializeVehicleForm() {
		if (this.vehicle) {
			this.vehicleForm = this.formBuilder.group({
				id: new FormControl(this.vehicle.id),
				vin: new FormControl(this.vehicle.vin, [Validators.required]),
				unit_number: new FormControl(this.vehicle.unit_number, [Validators.required]),
				license_number: new FormControl(this.vehicle.license_number, [Validators.required]),
				milage: new FormControl(this.vehicle.milage, Validators.required),
				is_fleet_vehicle: new FormControl({ value: this.vehicle.is_fleet_vehicle, disabled: !this.userService.isAdmin() }, [Validators.required]),
			});
		}
	}

	public onVehicleFormSubmit = () => {
		// If we are not an admin or we are not a manager then do not allow the inventory to be edited
		if (!this.userService.isAdmin() && !this.userService.isManager()) {
			SnackBarService.openSnackBarAlert('Sorry, you do not have the required permissions to edit this vehicles inventory', 'red');
			return Promise.resolve();
		}

		//	Promise to be returned
		let vehicleSubmitted = Promise.resolve();

		if (!this.vehicleForm || this.vehicleForm.invalid) {
			this.vehicleForm.markAllAsTouched();
			return vehicleSubmitted;
		}

		if (this.vehicleForm.value.id) {
			vehicleSubmitted = this.api.updateVehicle(this.vehicleForm.value).toPromise();
		} else {
			vehicleSubmitted = this.api
				.createVehicle(this.vehicleForm.value)
				.toPromise()
				.then((id) => {
					return Promise.resolve(this.vehicleForm.get('id').patchValue(id));
				});
		}

		//		submit the products
		return vehicleSubmitted
			.then(() => this.saveProducts(this.tableDataSource.data.filter((form: FormGroup) => form.dirty) as FormGroup[]))
			.then(() => this.getVehicleProducts())
			.then(() => {
				SnackBarService.openSnackBarAlert('Vehicle succesfully updated');
				this.onRefresh.emit();
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	};

	public onShowInventoryUI = () => {
		const dialogRef = this.dialog.open(VehicleInventory, { width: '80vw', height: '80vh', disableClose: true, closeOnNavigation: false });
		dialogRef.componentInstance.vehicleId = this._vehicle.id;
		dialogRef.componentRef.instance.inventoryUpdated.subscribe({
			next: () => {
				this.getVehicleProducts();
			},
		});

		return dialogRef
			.afterClosed()
			.toPromise()
			.catch((error) => {
				console.error(error);
			});
	};

	public toggleTransferMode = () => {
		this.isInTransferMode = !this.isInTransferMode;

		if (this.isInTransferMode) {
			this.selectedIdsForTransfer = new Set();

			let transferCheckBox = new EntitySelectorTableColumn();
			transferCheckBox.columnHeader = 'Transfer';
			transferCheckBox.columnProperty = 'transfer';
			transferCheckBox.columnWidth = '50px';
			transferCheckBox.type = EntitySelectorTableColumnType.checkbox;
			transferCheckBox.typeOptions = {
				change: (event: MatCheckboxChange, vehicleProduct: FormGroup) => {
					if (!vehicleProduct.controls.id.value) return;

					if (event.checked) {
						this.selectedIdsForTransfer.add(vehicleProduct.controls.id.value);
					} else {
						this.selectedIdsForTransfer.delete(vehicleProduct.controls.id.value);
					}
				},
			};

			//		Add column for transfer mode to the front of the column array
			this.selectorTableColumns.unshift(transferCheckBox);
		} else {
			//		Remove the column that was added for the transfer mode
			this.selectorTableColumns.shift();
		}

		this.entityTable.updateDisplayedColumns();

		return Promise.resolve();
	};

	public selectedTransferVehicleId: number = null;
	public onTransferSelectedProducts = async () => {
		if (!this.selectedTransferVehicleId) {
			SnackBarService.openSnackBarAlert('Please select a vehicle to transfer the products to', 'red');

			return;
		}

		await this.api
			.transferVehicleProducts({
				fromVehicleId: this.vehicle.id,
				toVehicleId: this.selectedTransferVehicleId,
				productIds: Array.from(this.selectedIdsForTransfer),
			})
			.toPromise()
			.then(() => {
				SnackBarService.openSnackBarAlert('Products successfully transfered');
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert('Sorry, there was an error transfering the products. Please try again later', 'red');
				console.error(error);
			});
	};

	public vehicleIdChange(event: number) {
		this.selectedTransferVehicleId = event;
	}

	/**
	 * Retrieves and sorts the products associated with the vehicle.
	 * Populates the form array with the products, updates the table data source,
	 * and calculates the total worth of the products.
	 * @returns A promise that resolves when the operation is completed.
	 */
	public getVehicleProducts(): Promise<any> {
		if (this.vehicle.id) {
			return this.api
				.getVehicleProducts(this.vehicle.id, false)
				.toPromise()
				.then((response) => {
					let vehicleProducts = response;
					vehicleProducts.sort((a, b) => {
						let aDescription = (a.description && a.description.toLowerCase()) || '';
						let bDescription = (b.description && b.description.toLowerCase()) || '';

						if (aDescription < bDescription) {
							return -1;
						}
						if (aDescription > bDescription) {
							return 1;
						}
						return 0;
					});
					this.vehicleProductsForm = new FormArray(
						vehicleProducts.map((product) => {
							return this.getProductForm(product);
						})
					);
					this.tableDataSource.data = this.vehicleProductsForm.controls;
					this.calculateTotalProductWorth();
				})
				.catch((error) => {
					console.error(error);
				});
		}

		return Promise.resolve();
	}

	/**
	 * @param product
	 * Product to get the form for
	 * */

	public getProductForm(product?: Product): FormGroup {
		if (!product) {
			product = new Product();
		}

		return this.formBuilder.group({
			id: new FormControl(product.id),
			cost: new FormControl(product.cost, [Validators.required, Validators.min(0)]),
			quantity: new FormControl(product.quantity, [Validators.required]),
			description: new FormControl(product.description, [Validators.required]),
			part_number: new FormControl(product.part_number, [Validators.required]),
		});
	}
}
