<nav>
	<mat-toolbar color="primary">
		<span>
			<strong>Imperial Truck Services Inc. {{ environmentTitle }} </strong>
		</span>

		<span style="position: absolute; right: 10px">
			<theme-switcher></theme-switcher>
		</span>

		<p class="accentColor" style="font-size: 10px; position: fixed; bottom: -5px; right: 5px">v{{ version }}</p>
	</mat-toolbar>
</nav>

<div class="flex-container flex-container-column flex-align-items-center mt-3 flex-gap">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>Imperial Truck Services, Inc.</h2>
		</mat-card-title>

		<mat-card-content>
			<form [formGroup]="loginForm">
				<mat-form-field>
					<mat-label>Username</mat-label>
					<input matInput required formControlName="username" name="username" type="text" autocomplete="username" autofocus />

					@if(formGroupHasError(loginForm, 'username', 'required')) {
					<mat-error> Username is <strong>required</strong> </mat-error>
					}
				</mat-form-field>

				<br />

				<mat-form-field>
					<mat-label>Password</mat-label>
					<input matInput required formControlName="password" name="password" type="password" autocomplete="password" />

					@if(formGroupHasError(loginForm, 'password', 'required')) {
					<mat-error> Password is <strong>required</strong> </mat-error>
					}
				</mat-form-field>
			</form>
		</mat-card-content>

		<mat-divider class="mt-3"></mat-divider>
		<br />

		<!-- <div class="mt-3">
			<span style="font-style: italic; font-size: 8px"><strong>Login with google coming soon...</strong></span>
			 <google-login-button></google-login-button> 
		</div> -->
		<mat-card-footer class="flex-container flex-container-row ml-3 mb-3">
			<loading-button [runOnEnter]="true" [text]="'Login'" [action]="loginClick"></loading-button>
		</mat-card-footer>

		@if (requestError) {
		<mat-error class="mt-3"> {{ requestError }} </mat-error>
		}
	</mat-card>
</div>
