<div>
	@if(appSettings && appSettings.settings) {
	<mat-form-field class="w-100">
		<mat-label>New Purchase Order Recipients</mat-label>
		<input placeholder="Comma separated list of emails" [(ngModel)]="appSettings.settings.purchaseOrderEmailRecipients" matInput type="text" />
	</mat-form-field>
	}

	<loading-button buttonClass="w-100" matSuffix [text]="'Save App Settings'" [action]="submitAppSettings"></loading-button>
</div>
