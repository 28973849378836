import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	constructor() { }

	private makeKey(storageKey: string, uniqueId: string | number): string {
		return `${storageKey}:${uniqueId}`;
	}

	public has(storageKey: string, uniqueId: string | number): boolean {
		return localStorage.getItem(this.makeKey(storageKey, uniqueId)) ? true : false;
	}

	public remove(storageKey: string, uniqueId: string | number): void {
		localStorage.removeItem(this.makeKey(storageKey, uniqueId));
	}

	public get(storageKey: string, uniqueId: string | number): any {
		return localStorage.getItem(this.makeKey(storageKey, uniqueId));
	}

	public add(storageKey: string, uniqueId: string | number, value: any): void {
		localStorage.setItem(this.makeKey(storageKey, uniqueId), JSON.stringify(value));
	}
}