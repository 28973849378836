<ng-template #buttonInnerHTML>
	@if (!loading && !loadingService.loading) { @if(iconClass) {
	<mat-icon [fontIcon]="iconClass"> {{ text}} </mat-icon>
	} @else {
	<div>{{ text }}</div>
	} } @if(loading || loadingService.loading) {
	<mat-spinner [diameter]="25" [color]="materialColor"></mat-spinner>
	}
</ng-template>

<ng-container>
	@switch (materialType) { @case ('mat-raised-button') {
	<div>
		<button
			[disabled]="loading || loadingService.loading || getDisabled()"
			(click)="clicked()"
			[ngClass]="buttonClass"
			mat-raised-button
			[color]="materialColor">
			<ng-container *ngTemplateOutlet="buttonInnerHTML"></ng-container>
		</button>
	</div>
	} @case ('mat-mini-fab') {
	<div>
		<button
			[disabled]="loading || loadingService.loading || getDisabled()"
			(click)="clicked()"
			[ngClass]="buttonClass"
			mat-mini-fab
			[color]="materialColor">
			<ng-container *ngTemplateOutlet="buttonInnerHTML"></ng-container>
		</button>
	</div>
	} @default { No Valid Material Button Type Supplied to LoadingButtonComponent } }
</ng-container>
