import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class FormComponent {
	public formGroupHasError(form: UntypedFormGroup, controlName: string, errorString: string): boolean {
		let hasError = false;

		if (form) {
			if (controlName) {
				let control = form.get(controlName);

				if (control) {
					hasError = control.hasError(errorString);
				}
			} else {
				hasError = form.hasError(errorString);
			}
		}

		return hasError;
	}

	public formControlHasError(control: UntypedFormControl, error: string): boolean {
		let hasError = false;

		if (control && control.touched) {
			hasError = control.hasError(error);
		}

		return hasError;
	}
}
