@if(!selectedStore) {
<div class="flex-container flex-container-column flex-align-items-center flex-gap m-3">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>Stores</h2>
		</mat-card-title>

		<mat-card-content>
			@if(!loadingService.loading) {
			<div style="max-width: calc(100vw - 158px); overflow: auto">
				<entity-selector-table [tableMaxHeight]="'50vh'" [dataSource]="tableDataSource" [selectorTableColumns]="selectorTableColumns">
				</entity-selector-table>
			</div>
			} @else {
			<div class="flex-container flex-align-items-center flex-justify-center p-3">
				<loading-spinner></loading-spinner>
			</div>
			}
		</mat-card-content>
		<mat-card-footer>
			<button (click)="newStoreClicked()" mat-raised-button color="primary">New Store</button>
		</mat-card-footer>
	</mat-card>
</div>

} @if (selectedStore) {
<div class="flex-container flex-container-column flex-justify-center flex-align-items-center flex-gap">
	<mat-card>
		<mat-card-title>
			<button mat-icon-button (click)="backClicked()">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>

		<mat-card-content>
			<store (onRefresh)="loadStores($event)" [store]="selectedStore"> </store>
		</mat-card-content>
	</mat-card>
</div>
}
