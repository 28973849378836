<button matTooltip="Select theme" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>format_color_fill</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="darkThemes">Dark Themes</button>
    <button mat-menu-item [matMenuTriggerFor]="lightThemes">Light Themes</button>
</mat-menu>

<mat-menu #darkThemes="matMenu">
    <button *ngFor="let theme of themeService.getDarkThemes()" (click)="selectTheme(theme)" mat-menu-item>
        {{ theme.name }}
    </button>
</mat-menu>

<mat-menu #lightThemes="matMenu">
    <button *ngFor="let theme of themeService.getLightThemes()" (click)="selectTheme(theme)" mat-menu-item>
        {{ theme.name }}
    </button>
</mat-menu>