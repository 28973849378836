import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { PurchaseOrder, Stage } from '../../../classes/purchaseOrder';
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../../entity_selector_table/entitySelectorTable';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { LoadingService } from 'src/app/services/loading.service';
import { DatePipe } from '@angular/common';

@Component({
	templateUrl: './receiving.html',
	styleUrls: ['./receiving.scss'],
	selector: 'receiving',
})
export class ReceivingComponent implements OnInit, AfterViewInit {
	public selectedOrder: PurchaseOrder = null;

	public tableDataSource = new MatTableDataSource();
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	constructor(
		public userService: UserService,
		private api: ApiService,
		private dialog: MatDialog,
		public loadingService: LoadingService,
		private datePipe: DatePipe
	) {}

	public ngOnInit() {
		this.setupEntitySelectorTableDataSource();
		this.loadOrders();
	}

	public ngAfterViewInit() {}

	public setupEntitySelectorTableDataSource() {
		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = '';
		editButton.columnProperty = 'edit';
		editButton.columnWidth = '50px';
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: 'edit',
			click: (order: PurchaseOrder) => {
				this.selectedOrder = order;
			},
		};
		this.selectorTableColumns.push(editButton);

		if (this.userService.isAdmin()) {
			let deleteButton = new EntitySelectorTableColumn();
			deleteButton.columnHeader = '';
			deleteButton.columnProperty = 'delete';
			deleteButton.columnWidth = '50px';
			deleteButton.type = EntitySelectorTableColumnType.asyncButton;
			deleteButton.typeOptions = {
				icon: 'delete',
				click: (order: PurchaseOrder) => {
					if (order.id) {
						let componentRef = this.dialog.open(ConfirmModal);
						componentRef.componentInstance.dialogue = 'Are you sure you would like to delete this purchase order?';

						return componentRef
							.afterClosed()
							.toPromise()
							.then((result) => {
								if (result) {
									return this.api
										.deletePurchaseOrder(order.id)
										.toPromise()
										.then(() => {
											return this.loadOrders();
										});
								}
							})
							.catch((error) => {
								console.error(error);
							});
					}

					return Promise.resolve();
				},
				materialType: 'mat-mini-fab',
			};
			this.selectorTableColumns.push(deleteButton);
		}

		let nameColumn = new EntitySelectorTableColumn();
		nameColumn.columnHeader = 'Name';
		nameColumn.columnProperty = 'name';
		nameColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(nameColumn);

		let submittedTodayColumn = new EntitySelectorTableColumn();
		submittedTodayColumn.columnHeader = 'Received On';
		submittedTodayColumn.columnProperty = 'createdAt';
		submittedTodayColumn.type = EntitySelectorTableColumnType.data;
		submittedTodayColumn.getData = (purchaseOrder: PurchaseOrder) => {
			let date = this.datePipe.transform(purchaseOrder.createdAt, 'MM/dd/yyyy');

			let today = new Date();
			let poDate = new Date(purchaseOrder.createdAt);

			if (today.getDate() == poDate.getDate() && today.getMonth() == poDate.getMonth() && today.getFullYear() == poDate.getFullYear()) {
				date = `New - ${date}`;
			}

			return date;
		};

		this.selectorTableColumns.push(submittedTodayColumn);

		this.tableDataSource.filterPredicate = (order: PurchaseOrder, filterText: string) => {
			let name = order.name;
			if (name == null || name == undefined) name = '';

			return name.toLowerCase().indexOf(filterText.trim()) > -1;
		};
	}

	public refreshPurchaseOrders() {
		let currentOrder = this.selectedOrder;

		this.loadOrders().then(() => {
			if (currentOrder && currentOrder.id) {
				let theOrder: PurchaseOrder = this.tableDataSource.data.find((order: PurchaseOrder) => order.id == currentOrder.id) as PurchaseOrder;

				if (theOrder) {
					this.selectedOrder = theOrder;
				}
			}
		});
	}

	private loadOrders(): Promise<any> {
		return this.api
			.getAllPurchaseOrders()
			.toPromise()
			.then((results: any) => {
				let orders: Array<PurchaseOrder> = [];
				if (this.userService.isAdmin()) orders = results;
				else orders = results.filter((order) => order.purchaseOrderProcessingStepId == Stage.InReceiving);

				if (orders && orders.length) {
					orders = orders.sort((a, b) => {
						let aOrder = a.id;
						let bOrder = b.id;

						if (aOrder > bOrder) return -1;
						if (aOrder < bOrder) return 1;
						return 0;
					});
				}

				this.tableDataSource.data = orders;
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	}

	public backClicked() {
		this.selectedOrder = null;
		//		Force filtering to reoccur
		this.tableDataSource.filter = this.tableDataSource.filter + ' ';
	}

	public purchaseOrderSavedHandler(event) {
		let selectedOrderId = this.selectedOrder.id;
		this.loadOrders();
		let order: PurchaseOrder = this.tableDataSource.data.find((order: PurchaseOrder) => order.id == selectedOrderId) as PurchaseOrder;
		if (order) {
			this.selectedOrder = order;
		}
	}
}
