<h1 mat-dialog-title>Select Products</h1>

<div mat-dialog-content>
	<div class="flex-container flex-container-column mb-3 mt-3">
		<mat-form-field class="example-full-width">
			<mat-label>Filter</mat-label>
			<input matInput [(ngModel)]="filterText" (ngModelChange)="filterTextChanged($event)" />
		</mat-form-field>
	</div>

	<div style="max-width: calc(100vw - 158px); overflow: auto; max-height: 50vh; height: 50vh">
		<div class="mt-3">
			<table class="table">
				<thead>
					<tr>
						<th scope="col"></th>
						<th scope="col">Description</th>
						<th scope="col">Part Number</th>
						<th scope="col">Cost</th>
						<th scope="col">Quantity</th>
					</tr>
				</thead>
				<tbody>
					@for(product of displayedProducts; track $index) {
					<tr>
						<td><mat-slide-toggle [(ngModel)]="product.selected"></mat-slide-toggle></td>
						<td>{{ product.description }}</td>
						<td>{{ product.part_number }}</td>
						<td>{{ product.cost }}</td>
						<td>{{ product.quantity }}</td>
					</tr>
					}
				</tbody>
			</table>
		</div>
	</div>
	<mat-paginator [length]="products.length" [pageSize]="pageSize" [pageSizeOptions]="[5,10,25,100]" (page)="pageChanged($event)"> </mat-paginator>
</div>

<div mat-dialog-actions>
	<button mat-button (click)="cancelClicked()">No Thanks</button>
	<button mat-button (click)="doneClicked()" cdkFocusInitial>Ok</button>
</div>
