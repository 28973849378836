import { Component, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Product } from 'src/app/classes/quote';

class ProductSelection extends Product {
	public selected: boolean;
}

@Component({
	templateUrl: './getVehicleProductsModal.html',
	selector: 'get-vehicle-products-modal',
	styleUrls: ['./getVehicleProductsModal.scss'],
})
export class GetVehicleProductsModal {
	@ViewChild(MatPaginator) paginator: MatPaginator;

	private _vehicleId: number = null;
	get vehicleId(): number {
		return this._vehicleId;
	}
	set vehicleId(id: number) {
		this._vehicleId = id;
		this.loadProducts();
	}

	public products: Array<ProductSelection> = [];
	public displayedProducts: Array<ProductSelection> = [];
	public filterText: string = null;
	public pageSize = 100;

	constructor(private api: ApiService, public dialogRef: MatDialogRef<GetVehicleProductsModal>) {}

	private loadProducts(): void {
		this.api.getVehicleProducts(this.vehicleId, false).subscribe(
			(response) => {
				this.products = response
					.map((product: any) => {
						product.selected = false;

						return product;
					})
					.sort((a: any, b: any) => {
						return a.description > b.description ? 1 : -1;
					});

				this.displayedProducts = this.products.slice(0, this.pageSize);
			},
			(error) => {
				console.error(error);
			}
		);
	}

	public pageChanged(event: PageEvent) {
		let start = event.pageSize * event.pageIndex;
		let end = start + event.pageSize;

		this.displayedProducts = this.products.slice(start, end);
	}

	public doneClicked(): void {
		this.dialogRef.close(this.products.filter((product) => product.selected));
	}

	public cancelClicked(): void {
		this.dialogRef.close(null);
	}

	public filterTextChanged(event) {
		this.displayedProducts = this.products
			.filter((product) => {
				if (product.description.toLocaleLowerCase().includes(event.toLocaleLowerCase())) return true;

				if (product.part_number.toLocaleLowerCase().includes(event.toLocaleLowerCase())) return true;

				return false;
			})
			.sort((a: any, b: any) => {
				return a.description > b.description ? 1 : -1;
			});

		if (this.paginator && event == '') {
			let page = new PageEvent();
			page.length = this.paginator.length;
			page.pageIndex = this.paginator.pageIndex;
			page.pageSize = this.paginator.pageSize;

			this.paginator.page.emit(page);
		}
	}
}
