import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    public isHandset: boolean = false;
    public isPhone: boolean = false;

    constructor(private breakPointObserver: BreakpointObserver) {
        this.breakPointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Medium, Breakpoints.Small]).subscribe(result => {
            if (result.matches) {
                this.isHandset = result.matches;
            } else {
                this.isHandset = false;
            }
        });

        this.breakPointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
            if(result.matches) {
                this.isPhone = result.matches;
            } else {
                this.isPhone = false;
            }
        });
    }
}