import { Component, NgZone } from '@angular/core';
import { AuthorizationService, GoogleUser } from '../authorization.service';

@Component({
	selector: 'google-profile-button',
	templateUrl: './profile.html',
	styleUrls: ['./profile.scss']
})
export class GoogleProfileButton {
	public user: GoogleUser = null;
	public showMenu: boolean = false;

	constructor(public authorization: AuthorizationService, public zone: NgZone) {
		this.authorization.loggedIn.subscribe((user: GoogleUser) => {
			this.zone.run(() => {
				this.user = user;
			});
		});

		this.authorization.loggedOut.subscribe(() => {
			this.zone.run(() => {
				this.user = null;
			});
		});

		if (this.authorization.googleAuth && this.authorization.isLoggedIn()) {
			this.user = this.authorization.getGoogleUserInfo();
		}
	}

	public openGmail() {
		window.open('https://mail.google.com', '_blank');
	}
}