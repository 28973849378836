@if(!selectedCustomer) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>Customers</h2>
		</mat-card-title>

		<mat-card-content>
			@if(!loadingService.loading) {
			<div style="max-width: calc(100vw - 158px); overflow: auto">
				<entity-selector-table [tableMaxHeight]="'50vh'" [dataSource]="tableDataSource" [selectorTableColumns]="selectorTableColumns">
				</entity-selector-table>
			</div>
			} @else {
			<div class="flex-container flex-container-row flex-align-items-center flex-justify-center p-3">
				<loading-spinner></loading-spinner>
			</div>
			}
		</mat-card-content>

		<mat-card-footer>
			<button (click)="newCustomerClicked()" mat-raised-button color="primary">Create Customer</button>
		</mat-card-footer>
	</mat-card>
</div>
} @if(selectedCustomer) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3 p-3">
	<mat-card>
		<mat-card-title>
			<button mat-icon-button (click)="backClicked()">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>

		<mat-card-content>
			<customer (onRefresh)="loadCustomers($event)" [customer]="selectedCustomer"> </customer>
		</mat-card-content>
	</mat-card>
</div>
}
