import { Component, OnInit, OnDestroy, NgZone, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/modules/google/google.module';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import packageInformation from '../../../../package.json';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { environment } from '../../../environments/environment';

@Component({
	templateUrl: './login.html',
	styleUrls: ['./login.scss'],
	selector: 'login',
})
export class LoginComponent extends FormComponent implements OnInit, OnDestroy, AfterViewInit {
	public requestError = null;
	public loginForm: FormGroup;
	public version: string = packageInformation.version;
	public environmentTitle = environment.title;

	constructor(
		private api: ApiService,
		private userService: UserService,
		private router: Router,
		//private googleAuth: AuthorizationService,
		private formBuilder: FormBuilder,
		private zone: NgZone
	) {
		super();
	}

	private googleAuthSubscription: Subscription = null;
	public ngOnDestroy() {
		if (this.googleAuthSubscription) {
			this.googleAuthSubscription.unsubscribe();
		}
	}

	public ngOnInit() {
		this.loginForm = this.formBuilder.group({
			username: new FormControl('', [Validators.required]),
			password: new FormControl('', [Validators.required]),
		});
	}

	public ngAfterViewInit() {
		/*
		let authenticateWithGoogleId = () => {
			this.zone.run(() => {
				let id = this.googleAuth.getGoogleUserInfo().id;
				let loginFormInfo = this.loginForm.value;
				
				if(!loginFormInfo.username || !loginFormInfo.password) return;

				//      Navigate to the home page
				this.api.authenticateWithGoogleId(id, loginFormInfo.username, loginFormInfo.password).toPromise().then(response => {
					return this.handleSuccessfulAuthentication(response);
				}).catch(error => {
					if(this.googleAuth && this.googleAuth.isLoggedIn()) {
						this.googleAuth.logout();
					}

					this.requestError = "No user account found with linked google account or username / password provided are incorrect.";
				});
			});
		};

		this.googleAuth.isInitialized.then(() => {
			if(this.googleAuth.isLoggedIn()) {
				authenticateWithGoogleId();
			}
		});

		this.googleAuthSubscription = this.googleAuth.loggedIn.subscribe(() => {
			authenticateWithGoogleId();
		}, error => {
			console.error(error);
		});
		*/
	}

	public loginClick = () => {
		let user = this.loginForm.value;

		if (user.username.trim().length > 0 && user.password.trim().length > 0) {
			return this.api
				.authenticate(user.username, user.password)
				.toPromise()
				.then((response) => {
					return this.handleSuccessfulAuthentication(response);
				})
				.catch((response) => {
					if (response && response.error && response.error.message) this.requestError = response.error.message;
					else this.requestError = 'Error logging in!';

					this.loginForm.get('password').patchValue('');
				});
		} else {
			this.requestError = 'Username or password missing!';
			this.loginForm.get('password').patchValue('');

			return Promise.reject();
		}
	};

	public handleSuccessfulAuthentication(authenticationResponse) {
		let authenticationHandled = Promise.resolve();

		//      Set the user in the user service
		this.userService.createUserFromApiResponse(authenticationResponse.user);

		//      Cache our api token
		this.api.token = authenticationResponse.token;

		//		Clear our api call cache
		this.api.clearCache();

		//		Link google account if we are logged in
		/*if(this.googleAuth && this.googleAuth.isLoggedIn()) {
			let googleUser = this.googleAuth.getGoogleUserInfo();

			authenticationHandled = this.api.linkUserToGoogleAccount(this.userService.user.id, googleUser.id).toPromise();
		}*/

		return authenticationHandled.then(() => {
			//      Navigate to the home page
			this.router.navigateByUrl('/home');
		});
	}
}
