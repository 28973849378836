import { Component } from "@angular/core";
import { Theme, ThemeSwitcherService } from "./theme_switcher.service";

@Component({
	templateUrl: './theme_switcher.html',
	styleUrls: [],
	selector: 'theme-switcher'
})
export class ThemeSwitcher {
	constructor(public themeService: ThemeSwitcherService) { }

	public selectTheme(theme: Theme) {
		this.themeService.setTheme(theme);
	}
}