<div class="flex-container flex-container-column flex-gap flex-align-items-center flex-justify-center mt-3">
	<mat-card class="p-3 min-material-card-width">
		<mat-card-title>
			<h2>New Purchase Order</h2>
		</mat-card-title>

		<mat-card-content>
			@if(orderForm) {
			<form [formGroup]="orderForm">
				<store-picker
					[validators]="[{key: 'required', message: 'Store is <strong>required</strong>'}]"
					[formGroupParent]="orderForm"
					[formGroupControlName]="'store_id'">
				</store-picker>

				<br />

				<mat-form-field class="w-100">
					<mat-label>Total Cost</mat-label>
					<input formControlName="total_cost" matInput type="number" />

					@if(formGroupHasError(orderForm, 'total_cost', 'required')) {
					<mat-error> Total cost is <strong>required</strong> </mat-error>
					} @if(formGroupHasError(orderForm, 'total_cost', 'min')) {
					<mat-error>
						Total cost must be greater than or equal to
						<strong>0</strong>
					</mat-error>
					}
				</mat-form-field>

				<br />

				@if(this.orderForm.controls['stocked'].value == false && this.orderForm.controls['fuel'].value != true) {
				<mat-form-field class="w-100">
					<mat-label>Job Number</mat-label>
					<input [formControl]="jobControl" matInput placeholder="Enter Job Number" type="text" />
					@if(formControlHasError(jobControl, 'minlength')) {
					<mat-error> Job number is <strong>required</strong></mat-error>
					}
				</mat-form-field>
				}

				<br />

				<div class="flex-container flex-gap flex-align-items-left">
					<div>
						<button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
						<input hidden (change)="onFileChange($event)" #fileInput type="file" id="file" />
						<span style="margin-left: 15px" *ngIf="selectedFiles && selectedFiles.length > 0">{{ selectedFiles[0].name }}</span>
					</div>

					<div>
						<mat-slide-toggle (change)="stockedChange($event)" formControlName="stocked">Stocked</mat-slide-toggle>
					</div>

					<div>
						<mat-slide-toggle (change)="fuelChange($event)" formControlName="fuel">Fuel</mat-slide-toggle>
					</div>
				</div>

				<br />

				<div
					[class.flex-container-column]="device.isPhone"
					[class.flex-container-row]="!device.isPhone"
					class="flex-container flex-gap flex-align-items-center flex-justify-center">
					<mat-form-field class="w-100">
						<mat-label>Purchase Order Number</mat-label>
						<input readonly formControlName="name" matInput type="text" />

						@if(formGroupHasError(orderForm, 'name', 'required')) {
						<mat-error> Purchase order name is <strong>required</strong> </mat-error>
						}
					</mat-form-field>
				</div>
			</form>

			}

			<br />

			<mat-divider></mat-divider>

			<br />

			<div>
				<loading-button [text]="'Submit'" [action]="onPurchaseOrderFormSubmit"></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
