import { Injectable } from '@angular/core';

import { RepairOrdersComponent } from '../../components/repair_orders/repairOrdersComponent';
import { ConfirmModal } from '../../components/confirm_modal/confirmModal';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RepairOrderCanDeactivateGuard {

	constructor(private dialog: MatDialog) { }

	public canDeactivate(repairOrdersComponent: RepairOrdersComponent): Promise<boolean> {
		if (!repairOrdersComponent.repairOrder || !repairOrdersComponent.repairOrder.orderForm)
			return Promise.resolve(true);

		if (repairOrdersComponent.repairOrder.orderForm.dirty) {
			return new Promise((resolve, reject) => {
				let componentRef = this.dialog.open(ConfirmModal);
				componentRef.componentInstance.dialogue = 'Are you sure you want to continue? Any unsaved changes will be lost.';;

				componentRef.afterClosed().toPromise().then(result => {
					if (result)
						resolve(true);

					resolve(false);
				}).catch(error => resolve(false));
			});
		} else {
			return Promise.resolve(true);
		}
	}
}