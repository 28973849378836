@if(!selectedQuote) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card class="p-3">
		<mat-card-title>
			<h2>Quotes</h2>
		</mat-card-title>

		<mat-card-content class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
			<div>
				<mat-form-field>
					<mat-label>Search All Quotes</mat-label>
					<input [formControl]="quotesSearchInput" matInput [matAutocomplete]="auto" />
					<mat-autocomplete (optionSelected)="onSearchQuotesChange($event)" #auto="matAutocomplete">
						@for (quote of searchedQuotes | async; track quote) {
						<mat-option [value]="quote">
							<span>{{ quote.vehicle.unit_number }} - {{ quote.customer.name }}</span>
						</mat-option>
						}
					</mat-autocomplete>
				</mat-form-field>
			</div>

			<div class="mb-3">
				<loading-button [text]="'Load 200 more'" [action]="loadMoreQuotes"></loading-button>
			</div>

			@if(!initializing) {
			<div style="max-width: calc(100vw - 158px); overflow: auto">
				<entity-selector-table [tableMaxHeight]="'50vh'" [dataSource]="tableDataSource" [selectorTableColumns]="selectorTableColumns">
				</entity-selector-table>
			</div>
			} @else {
			<div class="flex-container flex-container-column flex-align-items-center flex-justify-center p-3">
				<loading-spinner></loading-spinner>
			</div>
			}
		</mat-card-content>

		<mat-card-footer>
			<button (click)="newQuoteClicked()" mat-raised-button color="primary">New Quote</button>
		</mat-card-footer>
	</mat-card>
</div>
} @if(selectedQuote) {
<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card>
		<mat-card-title>
			<button mat-icon-button (click)="backButtonClicked()">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>

		<mat-card-content>
			<quote (refreshQuotes)="onRefresh($event)" [quote]="selectedQuote"></quote>
		</mat-card-content>
	</mat-card>
</div>
}
