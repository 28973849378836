import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { MatTableDataSource } from '@angular/material/table';
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../../entity_selector_table/entitySelectorTable';
import Decimal from 'decimal.js';
import { trimToString } from 'src/app/services/Helper';
import { PurchaseOrder, Stage, PurchaseOrderProduct } from 'src/app/classes/purchaseOrder';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { SnackBarAlert, SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { Product } from 'src/app/classes/quote';
import { Vehicle } from 'src/app/classes/vehicle';

@Component({
	templateUrl: './receivingPurchaseOrder.html',
	styleUrls: ['./receivingPurchaseOrder.scss'],
	selector: 'receiving-purchase-order',
})
export class ReceivingPurchaseOrderComponent extends FormComponent implements OnInit, OnDestroy {
	public orderProductsForm: FormArray = null;
	public orderForm: FormGroup = null;

	_order: PurchaseOrder = null;
	get order(): PurchaseOrder {
		return this._order;
	}

	@Input('order')
	set order(value: PurchaseOrder) {
		if (value) {
			//      Create copy of the order and use it for editing
			this._order = JSON.parse(JSON.stringify(value));

			//      Create our form group instance
			this.initializeOrderForm();
		}
	}

	@Output('onRefresh')
	onRefresh = new EventEmitter<any>();

	public productsValueChangesSubscription: Subscription = null;
	public calculatedTotalCost: number = null;

	public tableDataSource = new MatTableDataSource();
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	constructor(public userService: UserService, private api: ApiService, private formBuilder: FormBuilder, public dialog: MatDialog) {
		super();
	}

	public ngOnInit() {
		let deleteButton = new EntitySelectorTableColumn();
		deleteButton.columnHeader = '';
		deleteButton.columnWidth = '75px';
		deleteButton.columnProperty = 'delete';
		deleteButton.type = EntitySelectorTableColumnType.asyncButton;
		deleteButton.typeOptions = {
			icon: 'delete',
			click: (productForm: FormGroup, index: number) => {
				return this.removeItem(index);
			},
			materialType: 'mat-mini-fab',
		};
		this.selectorTableColumns.push(deleteButton);

		let partNumberColumn = new EntitySelectorTableColumn();
		partNumberColumn.columnHeader = 'Part Number';
		partNumberColumn.columnWidth = '150px';
		partNumberColumn.inputLabel = 'Part Number';
		partNumberColumn.columnProperty = 'part_number';
		partNumberColumn.type = EntitySelectorTableColumnType.text;
		partNumberColumn.errors = [
			{
				message: 'Part number is required!',
				name: Validators.required.name,
			},
			{
				message: 'Duplicate part number identified.',
				name: 'duplicatePartNumber',
			},
		];

		this.selectorTableColumns.push(partNumberColumn);

		let quantityColumn = new EntitySelectorTableColumn();
		quantityColumn.columnHeader = 'Quantity';
		quantityColumn.inputLabel = 'Quantity';
		quantityColumn.columnWidth = '100px';
		quantityColumn.columnProperty = 'quantity';
		quantityColumn.type = EntitySelectorTableColumnType.number;
		quantityColumn.errors = [
			{
				message: 'Quantity is required!',
				name: Validators.required.name,
			},
			{
				message: 'Quantity must be greater than or equal to 0',
				name: Validators.min.name,
			},
		];

		this.selectorTableColumns.push(quantityColumn);

		let descriptionColumn = new EntitySelectorTableColumn();
		descriptionColumn.columnHeader = 'Description';
		descriptionColumn.inputLabel = 'Description';
		descriptionColumn.columnProperty = 'description';
		descriptionColumn.type = EntitySelectorTableColumnType.text;
		descriptionColumn.errors = [
			{
				message: 'Description is required!',
				name: Validators.required.name,
			},
		];

		this.selectorTableColumns.push(descriptionColumn);

		let costColumn = new EntitySelectorTableColumn();
		costColumn.columnHeader = 'Cost';
		costColumn.inputLabel = 'Cost';
		costColumn.columnWidth = '100px';
		costColumn.columnProperty = 'cost';
		costColumn.type = EntitySelectorTableColumnType.number;
		costColumn.errors = [
			{
				message: 'Cost is required!',
				name: Validators.required.name,
			},
			{
				message: 'Cost must be greater than or equal to 0',
				name: Validators.min.name,
			},
		];

		this.selectorTableColumns.push(costColumn);

		let subtotal = new EntitySelectorTableColumn();
		subtotal.columnHeader = 'Subtotal';
		subtotal.inputLabel = 'Subtotal';
		subtotal.columnWidth = '100px';
		subtotal.columnProperty = 'sub_total';
		subtotal.getData = (element: FormGroup) => {
			let data = '$0.00';
			let subtotalControl = element.get('sub_total');

			if (subtotalControl) {
				data = '$' + trimToString(subtotalControl.value, 2);
			}

			return data;
		};
		subtotal.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(subtotal);

		this.tableDataSource.filterPredicate = (productForm: FormGroup, filterText: string) => {
			let product: Product = productForm.value || {};

			let name = product.part_number + ' ' + product.description;
			if (name == null || name == undefined) name = '';

			name = name.toLowerCase().trim();

			return name.includes(filterText.trim());
		};
	}

	public onAddProductClicked = () => {
		let productAdded = Promise.resolve();

		let products = this.orderForm.get('products') as FormArray;

		if (products) {
			products.insert(0, this.getProductForm());
			this.tableDataSource.data = products.controls;
			this.tableDataSource.paginator.firstPage();
		}

		return productAdded;
	};

	public unsubscribeToFormChanges() {
		if (this.productsValueChangesSubscription) {
			this.productsValueChangesSubscription.unsubscribe();
			this.productsValueChangesSubscription = null;
		}
	}

	public ngOnDestroy() {
		this.unsubscribeToFormChanges();
	}

	public totalCostValidator = () => {
		let totalCostControl = this.orderForm.get('total_cost') as FormControl;
		let error = {
			calculatedCostMismatch: { value: this.calculateTotalCost },
		};

		if (totalCostControl && Math.abs(this.calculatedTotalCost) == Math.abs(totalCostControl.value)) {
			error = null;
		}

		if (error) {
			SnackBarService.openSnackBarAlert(`The calculated cost of $${this.calculatedTotalCost} does not match the total cost`, 'red');
		}

		return error;
	};

	public initializeOrderForm() {
		if (this.order) {
			this.orderForm = this.formBuilder.group({
				id: new FormControl(this.order.id),
				user_id: new FormControl(this.order.user_id),
				store_id: new FormControl(this.order.store_id),
				name: new FormControl(this.order.name),
				products: new FormArray(
					this.order.products.map((item) =>
						this.formBuilder.group({
							id: new FormControl(item.id),
							productId: new FormControl(item.productId),
							purchaseOrderId: new FormControl(item.purchaseOrderId),
							description: new FormControl(item.description, [Validators.required]),
							part_number: new FormControl(item.part_number, [Validators.required]),
							cost: new FormControl(item.cost, [Validators.required, Validators.min(0)]),
							quantity: new FormControl(item.quantity, [Validators.required]),
							sub_total: new FormControl(0),
						})
					),
					[Validators.required]
				),
				total_cost: new FormControl(this.order.total_cost),
				deletedProducts: new FormControl([]),
				purchaseOrderProcessingStepId: Stage.Closed,
				stocked: new FormControl({ value: this.order.stocked || false, disabled: true }),
				fuel: new FormControl({ value: this.order.fuel || false, disabled: true }),
			});

			let updateAllProductsSubtotals = () => {
				let products = this.orderForm.get('products') as FormArray;
				if (products) {
					products.controls.forEach((control) => {
						this.updateProductSubtotal(control as FormControl);
					});
				}
			};

			updateAllProductsSubtotals();
			this.productsValueChangesSubscription = this.orderForm.valueChanges.subscribe((value) => {
				updateAllProductsSubtotals();
			});

			this.tableDataSource.data = (this.orderForm.get('products') as FormArray).controls;
			this.calculateTotalCost();
		}
	}

	public onPurchaseOrderFormSubmit = () => {
		let purchaseOrderSubmitted = Promise.resolve();

		if (!this.orderForm || this.orderForm.invalid || this.totalCostValidator()) {
			return purchaseOrderSubmitted;
		}

		//      First add the new products and edit the existing ones
		purchaseOrderSubmitted = this.upsertPurchaseOrder()
			.then(() => {
				SnackBarService.openSnackBarAlert('Purchase order saved.');
				this.orderForm.markAsPristine();
				this.onRefresh.emit();
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
			});

		return purchaseOrderSubmitted;
	};

	public upsertPurchaseOrder(): Promise<any> {
		return this.api
			.updatePurchaseOrder(this.orderForm.getRawValue())
			.toPromise()
			.then((result) => {
				//      Next, associate the prodcuts with the purchase order
				return this.api
					.addPurchaseOrderProducts({ purchaseOrderId: this.order.id, products: this.orderForm.get('products').value })
					.toPromise()
					.then((response) => {
						let productsToDelete = this.orderForm.get('deletedProducts').value;

						//      Attach the quantity back onto the response object since it is not returned by the api
						if (response)
							response.map((product) => {
								var formProduct = this.orderForm.get('products').value.find((formProduct) => formProduct.part_number == product.part_number);
								if (formProduct) {
									product.quantity = formProduct.quantity;
									product.cost = formProduct.cost;
								}
							});

						//		Need to get the vehicle that is tied to the user of the purchase order
						return this.api
							.getVehicleByUserId(this.order.user_id)
							.toPromise()
							.then((vehicle: Vehicle) => {
								//      Delete any products from the purchase order
								if (productsToDelete && productsToDelete.length > 0)
									//      First delete the products from the purchase order
									//      Then delete the products from the vehicle
									return this.api
										.deletePurchaseOrderProducts({ purchaseOrderId: this.order.id, products: productsToDelete })
										.toPromise()
										.then(() => {
											return this.api
												.deleteVehicleProducts({ vehicleId: vehicle.id, products: productsToDelete })
												.toPromise()
												.then(() => {
													return this.api
														.createVehicleProducts({
															vehicleId: vehicle.id,
															products: response,
														})
														.toPromise();
												});
										});
								else {
									return this.api
										.createVehicleProducts({
											vehicleId: vehicle.id,
											products: response,
										})
										.toPromise();
								}
							});
					});
			});
	}

	public getProductForm(product?: Product): FormGroup {
		if (!product) {
			product = new Product();
		}

		return this.formBuilder.group({
			id: new FormControl(product.id),
			cost: new FormControl(product.cost, [Validators.required, Validators.min(0)]),
			quantity: new FormControl(product.quantity, [Validators.required]),
			description: new FormControl(product.description, [Validators.required]),
			part_number: new FormControl(product.part_number, [Validators.required]),
			sub_total: new FormControl(0.0),
		});
	}

	public onDownloadReceipt = () => {
		return this.api
			.downloadFile(this.order.id)
			.toPromise()
			.then((response) => {
				let fileName = '';
				let date = new Date();

				fileName = date.getMonth() + 1 + '_' + date.getDate() + '_' + date.getFullYear() + '_purchaseorder_' + this.order.id;

				saveAs(response, fileName);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	private calculateTotalCost() {
		if (this.orderForm) {
			let items = this.orderForm.get('products') as FormArray;

			if (items) {
				let totalCost = new Decimal(0);

				items.controls.forEach((item) => {
					let subtotalControl = item.get('sub_total');

					if (subtotalControl) {
						let subtotal = new Decimal(subtotalControl.value || 0);

						totalCost = totalCost.add(subtotal);
					}
				});
				this.calculatedTotalCost = totalCost.toNumber();
			}
		}
	}

	public removeItem(itemIndex: number): Promise<any> {
		if (itemIndex && itemIndex < 0) return Promise.resolve();

		let componentRef = this.dialog.open(ConfirmModal);
		componentRef.componentInstance.dialogue = 'Are you sure you would like to remove this item?';

		return componentRef
			.afterClosed()
			.toPromise()
			.then((result) => {
				if (result) {
					if (this.orderForm) {
						let items = this.orderForm.get('products') as FormArray;

						if (items) {
							var item = items.value[itemIndex];

							//	If the item has an id, then remove it since we know its already attached to the purchase order
							if (item && item.id) {
								this.orderForm.get('deletedProducts').value.push(item);
							}
							items.removeAt(itemIndex);
							this.tableDataSource.data = items.controls;
						}

						this.orderForm.markAsDirty();
					}
				}
			})
			.catch((error) => {
				//      Dont need to do anything here
			});
	}

	public updateProductSubtotal(control: FormControl) {
		let product: PurchaseOrderProduct = control.value;

		if (product) {
			let quantity = new Decimal(product.quantity || 0);
			let cost = new Decimal(product.cost || 0);
			let subTotal = quantity.times(cost);
			let subtotalControl = control.get('sub_total');
			let currentValue = new Decimal(subtotalControl.value);

			if (subtotalControl && !currentValue.equals(subTotal)) {
				subtotalControl.patchValue(subTotal.toNumber());
			}
		}

		this.calculateTotalCost();
	}

	public onAddCore() {
		SnackBarService.openSnackBarAlert('Sorry, this functionality is not yet implemented.', 'orange');
		return Promise.resolve();
	}
}
