import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Injectable({
	providedIn: 'root'
})
export class PdfMakeService {
	public pdfMake:any = null;

	constructor() {
		pdfMake.vfs = pdfFonts.pdfMake.vfs;
		this.pdfMake = pdfMake;
	}
}