import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'confirm-modal',
	template: `
		<div style="max-width: 60vw">
			<h1 mat-dialog-title>{{ dialogue }}</h1>
			<div mat-dialog-content>
			</div>
			<div mat-dialog-actions>
				<button mat-button (click)="onNoClick()">No Thanks</button>
				<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
			</div>
		</div>
	`
})
export class ConfirmModal {
	public dialogue: string = 'Are you sure you want to do that?';

	constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ConfirmModal>) {
		if (data) {
			if (data.hasOwnProperty('dialogue')) this.dialogue = data.dialogue;
		}
	}

	public onNoClick(): void {
		this.dialogRef.close();
	}
}
