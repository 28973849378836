import { Input, Component, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormComponent } from './FormComponentHelper';

/*

	Extendable class that takes 2 inputs to use for reactive forms.
	Since angular forms doesnt automatically pick up parent form groups automatically,
	this allows the user to supply the form group and control name for use.

*/

//		Angular needs the component decorator to compile properly
//		These should be overridden by the class extending it
@Component({
	selector: '',
	template: '',
})
export abstract class ReactiveFormWrapper extends FormComponent {
	private _formGroupParent: UntypedFormGroup;
	get formGroupParent(): UntypedFormGroup {
		return this._formGroupParent;
	}
	@Input() set formGroupParent(formGroup: UntypedFormGroup) {
		this._formGroupParent = formGroup;
		this.setControl();
	}

	public _formGroupControlName: string;
	get formGroupControlName(): string {
		return this._formGroupControlName;
	}
	@Input() set formGroupControlName(name: string) {
		this._formGroupControlName = name;
		this.setControl();
	}

	@Input() validators: any[] = [];

	public control: UntypedFormControl = new UntypedFormControl();

	@Output() controlSet = new EventEmitter<any>();
	public setControl() {
		if (this.formGroupParent && this.formGroupControlName) {
			this.control = this.formGroupParent.get(this.formGroupControlName) as UntypedFormControl;
			this.controlSet.emit();
		}
	}
}
