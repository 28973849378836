import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreventativeMaintenanceConfiguration, PreventativeMaintenanceField } from 'src/app/classes/preventative_maintenance';
import { ApiService } from 'src/app/services/api.service';
import { MatSelect } from '@angular/material/select';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { DeviceService } from 'src/app/services/device.service';

@Component({
	selector: 'preventative-maintenance-configuration',
	styleUrls: ['./configuration.component.scss'],
	templateUrl: './configuration.component.html',
})
export class PreventativeMaintenanceConfigurationComponent {
	public configuration: PreventativeMaintenanceConfiguration = null;

	@ViewChild(MatSelect) selectedDataType: MatSelect;

	constructor(
		public activatedRoute: ActivatedRoute,
		public api: ApiService,
		public dialog: MatDialog,
		public location: Location,
		public device: DeviceService
	) {
		let customerId = this.activatedRoute.snapshot.params['customerId'];
		let configurationId = this.activatedRoute.snapshot.params['configurationId'];

		if (configurationId) {
			this.api
				.getPreventativeMaintenanceConfiguration(configurationId)
				.toPromise()
				.then((configuration: PreventativeMaintenanceConfiguration) => {
					this.configuration = configuration;
				});
		} else if (customerId) {
			this.configuration = new PreventativeMaintenanceConfiguration();
			this.configuration.fields = [];
			this.configuration.title = '';
			this.configuration.customer_id = customerId;
		}
	}

	public saveConfiguration() {
		this.api
			.savePreventativeMaintenanceConfiguration(this.configuration)
			.toPromise()
			.then((id) => {
				if (id) {
					this.configuration.id = id;
				}

				SnackBarService.openSnackBarAlert('Configuration saved successfully');
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	}

	public deleteConfiguration() {
		let componentRef = this.dialog.open(ConfirmModal);
		componentRef.componentInstance.dialogue = 'Are you sure you would like to delete this configuration?';

		return componentRef
			.afterClosed()
			.toPromise()
			.then((result) => {
				if (result) {
					if (this.configuration.id) {
						return this.api
							.deletePreventativeMaintenanceConfiguration(this.configuration.id)
							.toPromise()
							.catch((error) => {
								SnackBarService.openSnackBarAlert(error.error.message, 'red');
								console.error(error);
							});
					} else {
						return Promise.resolve();
					}
				}
			})
			.then(() => {
				SnackBarService.openSnackBarAlert('Configuration deleted');
				this.location.back();
			})
			.catch((error) => {});
	}

	public drop(event: CdkDragDrop<PreventativeMaintenanceField[]>) {
		moveItemInArray(this.configuration.fields, event.previousIndex, event.currentIndex);
	}

	public addField() {
		let field = new PreventativeMaintenanceField();
		field.title = '';

		if (!this.configuration.fields) {
			this.configuration.fields = [];
		}

		this.configuration.fields.push(field);
	}

	public removeField(theField: PreventativeMaintenanceField) {
		if (this.configuration && this.configuration.fields) {
			let fieldIndex = this.configuration.fields.findIndex((field) => field === theField);

			if (fieldIndex > -1) {
				this.configuration.fields.splice(fieldIndex, 1);
			}
		}
	}
}
