import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { SnackBarService } from '../components/snack_bar_alert/snackBarAlert';

@Injectable({ providedIn: 'root' })
export class isAdminGuard  {
	constructor(public userService:UserService, public router: Router) {}
	canActivate(): boolean {
		if (this.userService.isAdmin()) {
			return true;
		}

		SnackBarService.openSnackBarAlert('Sorry, you do not have permission to view that page.', 'red');
		return false;
	}
}