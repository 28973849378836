import { Component, computed, model } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { DateTime } from 'luxon';

export enum TimeOfDay {
	AM = 0,
	PM = 1
}

@Component({
	selector: 'time-picker',
	templateUrl: './datetime_picker.html',
	styleUrl: './datetime_picker.scss'
})
export class DateTimePicker {
	public datetime = model.required<DateTime>();
	protected hour = computed(() => {
		return ((this.datetime().hour + 11) % 12 + 1);
	});
	protected minute = computed(() => this.datetime().minute);
	protected timeOfDay = computed(() => this.datetime().hour > 11 ? TimeOfDay.PM : TimeOfDay.AM);

	public readonly = model<boolean>(false);

	constructor() { }

	public dateChanged(event: MatDatepickerInputEvent<Date>) {
		let newDateTime = DateTime.fromJSDate(event.value);
		let hourAndMinuteUpdate = {
			hour: 0,
			minute: 0,
		};

		if (this.hour()) {
			hourAndMinuteUpdate.hour = this.hour();
		}

		if (this.minute()) {
			hourAndMinuteUpdate.minute = this.minute();
		}

		this.datetime.set(newDateTime.set(hourAndMinuteUpdate));
	}

	public hourChanged(event: InputEvent) {
		let nextHour = parseInt((event.target as HTMLInputElement).value);
		nextHour = ((nextHour + 11) % 12 + 1)

		if (this.timeOfDay() == TimeOfDay.PM) {
			nextHour += 12;

			if (nextHour == 24) nextHour = 12;
		}

		if (this.timeOfDay() == TimeOfDay.AM) {
			if (nextHour == 12) nextHour = 0;
		}

		this.datetime.update(currentDatetime => {
			return currentDatetime.set({ hour: nextHour, day: currentDatetime.day });
		});
	}

	public minuteChanged(event: InputEvent) {
		let minute = (event.target as HTMLInputElement).value;

		this.datetime.update(currentDatetime => {
			return currentDatetime.set({ minute: parseInt(minute) });
		});
	}

	public chipClicked(event) {
		if (this.timeOfDay() == TimeOfDay.AM) {
			this.datetime.update(currentDate => currentDate.set({ hour: currentDate.hour + 12, day: currentDate.day }));
		} else {
			this.datetime.update(currentDate => currentDate.set({ hour: currentDate.hour - 12, day: currentDate.day }));
		}
	}
}