import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	templateUrl: './app.html',
	styleUrls: ['./app.scss'],
	selector: 'app'
})
export class AppComponent {
	constructor(private router: Router) {
		//      Auto route to the login page
		this.router.navigate(['/login']);
	}
}