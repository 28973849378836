import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { Quote } from 'src/app/classes/quote';
import { ApiService } from 'src/app/services/api.service';
import { padToLength } from 'src/app/services/Helper';
import { LocalStorageService } from 'src/app/services/local_storage.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmModal } from '../confirm_modal/confirmModal';
import { EntitySelectorTableColumn, EntitySelectorTable, EntitySelectorTableColumnType } from '../entity_selector_table/entitySelectorTable';
import { SnackBarService } from '../snack_bar_alert/snackBarAlert';
import { QuoteComponent as QuoteComponent } from './quote/quote';
import { FormControl } from '@angular/forms';

export const quotesStorageKey = 'quotes';

@Component({
	selector: 'quotes',
	styleUrls: ['./quotes.scss'],
	templateUrl: './quotes.html',
})
export class Quotes {
	public selectedQuote: Quote = null;
	public filterText = '';
	public offset = 0;

	public tableDataSource = new MatTableDataSource();

	public initializing: boolean = false;
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	@ViewChild(QuoteComponent) quote: QuoteComponent;
	@ViewChild(EntitySelectorTable) table: EntitySelectorTable;

	constructor(
		public userService: UserService,
		private api: ApiService,
		private storage: LocalStorageService,
		public dialog: MatDialog,
		public datePipe: DatePipe,
		public activatedRoute: ActivatedRoute
	) {}

	/**
	 * Initializes the selector table columns with specific configurations such as edit button, ID display, customer name, serviced by information, and vehicle unit number.
	 * Sets up filtering based on customer name and vehicle unit number.
	 * Initializes searching of repair orders and loads quotes based on query parameters.
	 */
	public ngOnInit() {
		this.initializing = true;

		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = '';
		editButton.columnProperty = 'edit';
		editButton.columnWidth = '60px';
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: 'edit',
			click: (quote: Quote) => {
				this.loadQuote(quote);
			},
		};
		this.selectorTableColumns.push(editButton);

		let idColumn = new EntitySelectorTableColumn();
		idColumn.columnHeader = 'Id';
		idColumn.columnWidth = '65px';
		idColumn.columnProperty = 'id';
		idColumn.type = EntitySelectorTableColumnType.data;
		idColumn.getData = (quote: Quote) => {
			if (!quote) {
				return 'Error, no ID found';
			}

			return `QN${padToLength(quote.id, 5)}`;
		};

		this.selectorTableColumns.push(idColumn);

		let nameColumn = new EntitySelectorTableColumn();
		nameColumn.columnHeader = 'Customer';
		nameColumn.columnProperty = 'customer.name';
		nameColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(nameColumn);

		let servicedBy = new EntitySelectorTableColumn();
		servicedBy.columnHeader = 'Serviced By';
		servicedBy.columnProperty = 'user';
		servicedBy.type = EntitySelectorTableColumnType.data;
		servicedBy.getData = (quote: Quote) => {
			if (!quote || !quote.user) {
				return 'Not set';
			}

			return `Emp# ${quote.user.employee_number || ''}`;
		};

		this.selectorTableColumns.push(servicedBy);

		let unitNumber = new EntitySelectorTableColumn();
		unitNumber.columnHeader = 'Vehicle Unit#';
		unitNumber.columnProperty = 'vehicle.unit_number';
		unitNumber.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(unitNumber);

		this.tableDataSource.filterPredicate = (quote: Quote, filterText: string) => {
			let name = quote.customer.name + quote.vehicle.unit_number;

			//		name == ' ' when we have to manually trigger filtering when the status filter is updated
			if (name == null || name == undefined || name == ' ') name = '';

			return name.trim().toLowerCase().indexOf(filterText.trim().toLowerCase()) > -1;
		};

		//      Initialize searching of repair orders
		this.searchQuotes();

		this.loadQuotes().then(() => {
			if (this.activatedRoute.snapshot.queryParams.id) {
				let quote = this.tableDataSource.data.find((quote: Quote) => quote.id == this.activatedRoute.snapshot.queryParams.id);

				if (quote) {
					this.loadQuote(quote);
				}
			}

			this.initializing = false;
		});
	}

	public backButtonClicked() {
		let userInputResolved: any = Promise.resolve();

		if (this.quote.quoteForm.dirty) {
			const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
			dialogRef.componentInstance.dialogue = 'It looks like you have not saved your quote. Would you like to save? ';

			if (this.selectedQuote.id) {
				dialogRef.componentInstance.dialogue += 'If not, your changes will be temporarily cached and can be loaded later.';
			}

			userInputResolved = dialogRef
				.afterClosed()
				.toPromise()
				.then((result) => {
					if (result) {
						return this.quote.onQuoteSubmit(false).then(() => {
							this.onRefresh({ id: null, callback: null });
						});
					} else {
						//		Cache the unsaved changes for later
						this.storage.add(quotesStorageKey, this.quote.quoteForm.value.id, this.quote.quoteForm.value);
					}
				});
		}

		userInputResolved
			.then(() => {
				if (this.quote) {
					this.quote.tabView.selectedIndex = 0;
					this.quote.tabView.realignInkBar();
				}

				this.selectedQuote = null;
				//		Force filtering to reoccur
				this.tableDataSource.filter = this.tableDataSource.filter + ' ';
			})
			.catch((error) => {
				console.error(error);
			});
	}

	public loadQuote(quote) {
		if (quote) {
			if (quote.id && this.storage.has(quotesStorageKey, quote.id)) {
				const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
				dialogRef.componentInstance.dialogue =
					'It looks like last time you worked on this quote some changes went unsaved. Would you like to load your unsaved changes?';

				return dialogRef
					.afterClosed()
					.toPromise()
					.then((result) => {
						let cachedQuote = this.storage.get(quotesStorageKey, quote.id);

						//	If user wants to load the cached quote and the cached copy exists
						//	Else, if they dont want to load it then remove it from the cache
						if (result && cachedQuote) {
							quote = JSON.parse(cachedQuote);
						} else if (!result && cachedQuote) {
							this.storage.remove(quotesStorageKey, quote.id);
						}

						this.selectedQuote = quote;
					})
					.catch((error) => {
						//      Dont need to do anything here
					});
			} else {
				this.selectedQuote = quote;
				return Promise.resolve();
			}
		}

		return Promise.resolve();
	}

	public loadMoreQuotes = () => {
		this.offset += 200;
		return this.loadQuotes();
	};

	public onRefresh(args: { id: number; callback: any }) {
		//      Reset the offset to 0 to load the first 50
		//      Clear out the quotes array
		this.offset = 0;
		this.tableDataSource.data = [];

		let lineItemIndex = null;
		let tabIndex = null;
		if (this.quote) {
			lineItemIndex = this.quote.selectedLineItemIndex;
			tabIndex = this.quote.selectedTabIndex;
		}

		return this.loadQuotes()
			.then(() => {
				if (args.id) {
					let quote = this.tableDataSource.data.find((quote: Quote) => quote.id == args.id);

					if (quote) {
						if (this.quote) {
							let subscription = this.quote.initialized.subscribe(() => {
								if (lineItemIndex != null && tabIndex != null) {
									this.quote.selectedLineItemIndex = lineItemIndex;
									this.quote.selectLineItem(lineItemIndex);
								}

								//		Unsubscribe afterwards
								subscription.unsubscribe();
							});
						}

						return this.loadQuote(quote);
					}
				} else {
					this.selectedQuote = null;
				}
			})
			.finally(() => {
				if (args.callback) {
					args.callback();
				}
			});
	}

	public quotesSearchLoading = false;
	public quotesSearchInput = new FormControl('');
	public searchedQuotes: Observable<Array<any>> = null;

	private searchQuotes() {
		this.searchedQuotes = this.quotesSearchInput.valueChanges.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			tap(() => (this.quotesSearchLoading = true)),
			switchMap((term) =>
				this.api.searchQuotes(term).pipe(
					switchMap((response) => {
						return of(response);
					}),
					catchError(() => of([])),
					tap(() => (this.quotesSearchLoading = false))
				)
			)
		);
	}

	public onSearchQuotesChange(selectedItem: any) {
		if (selectedItem && selectedItem.id) {
			this.quotesSearchLoading = true;

			let downloadedQuote = this.tableDataSource.data.find((quote: Quote) => quote.id == selectedItem.id);
			if (downloadedQuote) {
				this.loadQuote(downloadedQuote);

				this.quotesSearchLoading = false;
			} else {
				this.loadQuote(selectedItem);

				this.quotesSearchLoading = false;
			}
		}
	}

	private loadQuotes(): Promise<any> {
		return this.api
			.getAllQuotes(this.offset, false)
			.toPromise()
			.then((results) => {
				let quotes: Quote[] = results;

				//		Filter down the repair orders to only the ones that the user is able to see
				if (!this.userService.isAdmin()) {
					quotes = quotes.filter((quote) => quote.user.id == this.userService.user.id);
				}

				this.tableDataSource.data = this.tableDataSource.data.concat(quotes);
			})
			.catch((error) => {
				console.error(error);
				SnackBarService.openSnackBarAlert(`Error occurred while loading repair quotes. Error: ${error}`, 'red');
			});
	}

	public newQuoteClicked = () => {
		this.selectedQuote = new Quote();
	};
}
