import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { SettingsService } from 'src/app/services/settings.service';

export class AppSettings {
	public id: number = null;
	public settings: Settings = new Settings();
}

class Settings {
	purchaseOrderEmailRecipients: string = null;
}

@Component({
	selector: 'app-settings',
	templateUrl: './app_settings.html',
	styleUrls: ['./app_settings.scss'],
})
export class AppSettingsComponent implements OnInit {
	public appSettings: AppSettings = new AppSettings();

	public constructor(public userService: UserService, private apiService: ApiService, public settingsService: SettingsService) {
		//      Check if settings exist
		this.apiService
			.getAppSettings()
			.toPromise()
			.then((settings: AppSettings) => {
				if (settings) {
					this.appSettings = settings;
				}
			});
	}

	public ngOnInit() {}

	submitAppSettings = () => {
		if (this.appSettings.id) {
			return this.apiService.updateAppSettings(this.appSettings).toPromise();
		} else {
			return this.apiService
				.createAppSettings(this.appSettings)
				.toPromise()
				.then((savedSettings) => {
					this.appSettings.id = savedSettings.id;
				});
		}
	};
}
