import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { trimToNumber } from 'src/app/services/Helper';
import { EntitySelectorTableColumn } from '../entity_selector_table/entitySelectorTable';

@Component({
	selector: 'product-upcharge-toggle',
	template: `
		<mat-button-toggle-group multiple (change)="selectionChanged($event)">
			<mat-button-toggle value=".1">10%</mat-button-toggle>
			<mat-button-toggle value=".2">20%</mat-button-toggle>
			<mat-button-toggle value=".3">30%</mat-button-toggle>
		</mat-button-toggle-group>
	`,
})
export class ProductUpchargeToggle {
	public data: UntypedFormGroup;
	public column: EntitySelectorTableColumn;
	public originalCost: number;

	constructor() {}

	public selectionChanged(event: MatButtonToggleChange) {
		if (event && this.data) {
			let toggle = event.source;
			if (toggle) {
				let group = toggle.buttonToggleGroup;
				if (event.value.some((item) => item == toggle.value)) {
					group.value = [toggle.value];
				}
			}

			this.data.value.upcharge = event.value && event.value.length ? Number(event.value[event.value.length - 1]) : null;

			if (!this.originalCost) {
				this.originalCost = this.data.value.cost;
			}

			this.data.value.cost = this.originalCost;

			if (this.data.value.upcharge) {
				this.data.value.cost = trimToNumber(this.data.value.cost + this.data.value.cost * this.data.value.upcharge, 2);
			}

			this.data.get('cost').patchValue(this.data.value.cost);
		}
	}
}
