import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'customer-labor-rate',
	styleUrls: [],
	template: `
		@if(selectedLaborRate) {
			<div class="flex-container flex-container-row flex-gap" [formGroup]="selectedLaborRate">
				<mat-form-field class="flex-grow-1">
					<mat-select #laborRateSelector (selectionChange)="laborRateSelected($event)">
						@for(laborRate of laborRates.value; track $index; let i = $index) {
							<mat-option [value]="i">{{ capitalizeFirstLetter(laborRate.equipment_type) }}</mat-option>
						}
					</mat-select>
				</mat-form-field>

				<mat-form-field class="flex-grow-1">
					<mat-label>Rate</mat-label>
					<input formControlName="rate" matInput type="number" required/>
				</mat-form-field>
			</div>
		}
    `
})
export class CustomerLaborRateComponent implements OnInit, AfterViewInit {
	@Input('laborRates') laborRates: FormArray<FormGroup>;
	public selectedLaborRate: FormGroup = null;

	@ViewChild(MatSelect) laborRateSelector: MatSelect;

	constructor(public changeDetector: ChangeDetectorRef) { }

	public ngOnInit(): void {
		if (this.laborRates) {
			this.selectedLaborRate = this.laborRates.at(0);
		}
	}

	public ngAfterViewInit(): void {
		if (this.selectedLaborRate && this.laborRateSelector) {
			this.laborRateSelector.options.forEach((item, index) => {
				if (index == 0) {
					item.select();
					this.changeDetector.detectChanges();
				}
			});
		}
	}

	public laborRateSelected(event: MatSelectChange) {
		this.selectedLaborRate = this.laborRates.at(event.value);
	}

	public capitalizeFirstLetter(value: string) {
		return value[0].toUpperCase() + value.slice(1, value.length);
	};


}

class CustomerLaborRate {
	equipment_type: string;
	rate: number;
}