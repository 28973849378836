import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
	selector: 'photo-input',
	template: `
		<div fxLayout="column" fxLayoutGap="15px">
			<label><strong>{{title}}</strong></label>
			<input [attr.readonly]="readonly" multiple type="file" (change)="fileProgress($event)">

			<div style="max-width: 615px; overflow-x: auto;" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
				<div *ngFor="let photoInput of photoPreviews; index as i;" fxLayout="column">
					<img downloadOnClick [src]="photoInput.photo" height="150" width="150" />
					<button (click)="removePhoto(photoInput, i)" mat-icon-button >
						<mat-icon *ngIf="!readonly">delete</mat-icon>
				  	</button>
				</div>
			</div>
		</div>
	`
})
export class PhotoInput implements OnInit {
	private _ids:number[] = [];
	@Input() set ids(value:number[]) {
		this._ids = value;
		this.idsChange.emit(this._ids);
	}
	get ids() : number[] {
		return this._ids;
	}
	@Output() idsChange:EventEmitter<number[]> = new EventEmitter();
	@Input() title:string = '';
	@Input() readonly:boolean = false;

	public files:FileList = null;
	public photoPreviews:any[] = [];

	constructor(public api:ApiService) { }

	public ngOnInit() {
		this.getExistingFiles();
	}

	public getExistingFiles() {
		if(this.ids && this.ids.length && Array.isArray(this.ids)) {
			this.ids.map(id => {
				this.api.getFile(id).toPromise().then(file => {
					let reader = new FileReader();
					reader.addEventListener("load", () => {
						this.photoPreviews.push({ id: id, photo: reader.result });
					}, false);

					if (file) {
						reader.readAsDataURL(file);
					}
				}).catch(error => {
					console.error(error);
				});
			});
		}
	}

	public removePhoto(photoInput, index) {
		if(index >= 0 && index < this.photoPreviews.length) {
			this.photoPreviews.splice(index, 1);
		}

		if(photoInput.id) {
			let idIndex = this.ids.indexOf(photoInput.id);

			if(idIndex > -1) {
				this.ids.splice(idIndex, 1);
			}
		}
		
	}

	public fileProgress(fileInput:any) {
		this.files = fileInput.target.files;

		for(let i = 0; i < this.files.length; ++i) {
			let file = this.files.item(i);
			let  mimeType = file.type;
			if (mimeType.match(/image\/*/) == null) {
			  return;
			}
		 
			let  reader = new FileReader();      
			reader.readAsDataURL(file); 
			reader.onload = (_event) => { 
			  this.photoPreviews.push({id: null, photo: reader.result });
			}
		}
	}

	public saveFiles() {
		let filesUploaded = [];
		
		if(this.files && this.files.length) {
			for(let i = 0; i < this.files.length; ++i) {
				let formData:FormData = new FormData();
				formData.append('files', this.files[i]);
	
				filesUploaded.push(this.api.postFile(formData).toPromise());
			}
		}

		return Promise.all(filesUploaded).then(results => {
			if(this.ids == null || this.ids == undefined) this.ids = [];

			this.ids = this.ids.concat(results);
			this.files = null;
			this.photoPreviews = [];

			this.getExistingFiles();
		});
	}
}