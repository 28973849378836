export class Vehicle {
	id: number;
	vin: string;
	unit_number: string;
	milage: number;
	license_number: string;
	equipment_type: string;
	is_fleet_vehicle: boolean;

	constructor(data?: Vehicle) {
		if (data) {
			this.id = data.id;
			this.vin = data.vin;
			this.unit_number = data.unit_number;
			this.milage = data.milage;
			this.license_number = data.license_number;
			this.equipment_type = data.equipment_type;
			this.is_fleet_vehicle = data.is_fleet_vehicle;
		}
	}
}

export enum EquipmentType {
	truck = 'truck',
	trailer = 'trailer',
	equipment = 'equipment',
}
