<div class="flex-container flex-container-column p-3">
	<mat-form-field class="datepicker">
		<mat-label>Choose a date</mat-label>
		<input matInput [value]="datetime().toJSDate()" (dateChange)="dateChanged($event)" [matDatepicker]="picker">
		<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
	</mat-form-field>

	<div class="flex-container flex-container-row flex-gap flex-justify-space-between datepicker">
		<mat-form-field class="timepicker">
			<mat-label>Hours</mat-label>
			<input type="number" matInput [value]="hour()" (change)="hourChanged($event)">
		</mat-form-field>

		<mat-form-field class="timepicker">
			<mat-label>Minutes</mat-label>
			<input type="number" matInput [value]="minute()" (change)="minuteChanged($event)">
		</mat-form-field>

		<mat-chip-set>
			<mat-chip (pointerdown)="chipClicked($event)">
				{{ timeOfDay() == 0 ? 'AM' :'PM'}}
			</mat-chip>
		</mat-chip-set>
	</div>

</div>