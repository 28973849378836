import { Component } from '@angular/core';
import { User } from '../../classes/user';
import { UserService } from '../../services/user.service';

@Component({
	templateUrl: './invoices.html',
	styleUrls: ['./invoices.scss'],
	selector: 'invoices'
})
export class InvoicesComponent {
	//      Get a copy of our current user object
	public user: User = JSON.parse(JSON.stringify(this.userService.getUser()));

	constructor(public userService: UserService) {
	}
}