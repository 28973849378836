import { effect, Injectable, signal } from '@angular/core';
import { UserService } from './user.service';
import { LocalStorageService } from './local_storage.service';

const USER_UI_SETTINGS_LOCAL_STORAGE_KEY = 'user-ui-settings';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	public uiSettings = signal<UserUISettings>(new UserUISettings());

	private updateLocalStorageUserUISettingsEffect = effect(() => {
		if (this.uiSettings != null && this.uiSettings != undefined) {
			this.localStorage.add(USER_UI_SETTINGS_LOCAL_STORAGE_KEY, this.userService.user.id, this.uiSettings());
		}
	});

	constructor(private userService: UserService, private localStorage: LocalStorageService) {
		try {
			//      See if a theme has been picked from the cache
			let user_ui_settings = localStorage.get(USER_UI_SETTINGS_LOCAL_STORAGE_KEY, this.userService.user.id) as any;

			if (!user_ui_settings) {
				this.uiSettings.set(new UserUISettings());
			} else {
				this.uiSettings.set(JSON.parse(user_ui_settings));
			}
		} catch (error) {
			this.localStorage.add(USER_UI_SETTINGS_LOCAL_STORAGE_KEY, this.userService.user.id, new UserUISettings());
		}
	}
}

export class UserUISettings {
	public toolbarVertical: boolean = true;
}
